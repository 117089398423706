import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { instanceWP } from "../../utils/axios-config";
import { format } from "date-fns";
import PPagination from "../../components/pagination/Pagination";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";


export const Offers = () => {

    const [ searchParams ] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [offers, setOffers] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [text, setText] = useState();
    const [ category, setCategory ] = useState();

    useEffect(() => {
        setText(searchParams.get('text') ? searchParams.get('text') : "");
        loadPageData(1, text, category);
    }, [searchParams]);

    const changeText = (e) => {
        e.persist();
        setText(e.target.value);
    }

    const loadPageData = async (startPage = 1, text, category) => {
        let buildUrl = `/oferta?per_page=8&page=${startPage}&acf_format=standard`;
        buildUrl = buildUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);
        buildUrl = buildUrl + (category !== null && category !== "" && category !== undefined ? `&oferta_categoria=${category}`: ``);
        const offerList = instanceWP.get(buildUrl);
        const offerCategories = instanceWP.get(`/oferta_categoria`);
        try{
            Promise.all([offerList,offerCategories]).then(function(values) {
                let offerList = [];
                let offerCategories = [];
                offerList = values[0];
                offerCategories = values[1];
                setOffers(offerList.data);
                setCategories(offerCategories.data);
                setTotalPages(parseInt(values[0].headers['x-wp-totalpages']));
            })
            /*await instanceWP.get(buildUrl).then(function(resp) {
                console.log(resp);
                setofferss(resp);
                setTotalPages(parseInt(resp.headers['x-wp-totalpages']));
            })*/
            .catch(function(resp) {
                Swal.fire({
                    icon: "error",
                    title: "error",
                    text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: "Aceptar"
                })
            }).finally(() => {
                setLoading(false);
                if (initialLoading) setInitialLoading(false);
            });
        }catch(error){
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        }
        
        
    }

    const changeCategory = (e) => {
        e.persist();
        setLoading(true);
        if(category === e.target.value){
            setCategory("");
            loadPageData(1, text, null);
        }else{
            setCategory(e.target.value);
            loadPageData(1, text, e.target.value);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        loadPageData(1, text, category);
    }

    return (
        <>
        <div className="cdc-offers">
            <Box component="form" className="cdc-offers-search" onSubmit={handleSubmit}>
                <p>Buscador</p>
                <div>
                    <input type="submit" id="search-submit" value="" />
                    <input name="text" type="text" placeholder="" defaultValue={text} onChange={changeText}/>
                </div>
            </Box>
            {initialLoading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {categories.length > 0 && <Box className="cdc-contact-filter">
                            <p>Selecciona Categoría</p>
                            <div>
                                {categories.map((cat, i) => {
                                    return(
                                        <button className={`cdc-contact-category ${parseInt(category) === cat.id ? "cdc-contact-category-active" : ""}`} value={cat.id} key={"offers-category" + i} onClick={changeCategory}>{cat.name}</button>
                                    )
                                })}
                            </div>
                        </Box>}
                        {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {offers.length > 0 ? (
                                    <ul className="cdc-offers-list">
                                        {offers.map((newElement, index) => {
                                            return (
                                                <Link className="cdc-offers-list-link" to={"/oferta/" + newElement.id} key={"offer-" + index}>
                                                    <li className="cdc-offers-container">
                                                        {newElement.acf.image &&
                                                        <figure>
                                                            <img src={newElement.acf.image} alt={"Imagen " + newElement.title.rendered}/>
                                                        </figure>
                                                        }
                                                        <Box className="cdc-offers-container-right">
                                                            <p className="cdc-offers-date">{format(new Date(newElement.date), "dd/MM/yyyy")}</p>
                                                            <h2 className="cdc-offers-title">{newElement.title.rendered}</h2>
                                                            <p className="cdc-offers-summary">{newElement.acf.summary}</p>
                                                        </Box>
                                                    </li>
                                                </Link>
                                            )
                                        })}
                                    </ul>
                                ) : (
                                    <div className="cdc-offers-notfound">No se han encontrado ofertas.</div>
                                )
                                }
                            </>
                        )}
                    </>
                )
            }
            <Box sx={{mb: 6, mt: 4}} align="center">
                <PPagination setLoading={setLoading} loadData={loadPageData} totalPages={totalPages} text={text} category={category}/>
            </Box>
        </div>
    </>
    );
}