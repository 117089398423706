import { useLocation } from "react-router-dom";

import { useAuth } from "../../firebase/Auth";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState} from "react";
import Swal from "sweetalert2";
import { instance } from "../../utils/axios-config";

export const NotificationRedsys = () => {
    
    const [ searchParams ] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();
    const [pagoRealizado, setpagoRealizado] = useState(false);
    const [emailMandado, setemailMandado] = useState(false);
    //let pagoRealizado = false;
    //let emailMandado = false;
    const body = window.localStorage.getItem("payment");
    

    useEffect(() => {
        if(searchParams.get('Ds_MerchantParameters')&& (!pagoRealizado && !emailMandado)){
            decodeSignature();
        }
      },[]);
      

    const checkPayment = (pagado, paymentResponse) => {
        if(pagado === false){
            sendForm();
            sendEmail(paymentResponse);
            //console.log("send form and mail")
        }
    }

    const decodeSignature =  () =>{
        let encode = searchParams.get('Ds_MerchantParameters') ? searchParams.get('Ds_MerchantParameters') : "";
        if(encode !== "" && encode !== undefined){
            console.log(encode);
             let decode = JSON.parse(window.atob(encode));
             if(decode){
                    setResponse(decode);
                    setLoading(false);
                    if(decode.Ds_Response === "0000"){
                        checkPayment(pagoRealizado, decode);
                        setpagoRealizado(true);
                        //pagoRealizado = true;
                    }else{
                        if(emailMandado === false){
                            sendEmail(decode);
                            setemailMandado(true);
                            //emailMandado = true;
                        }  
                    }
             }
        }
    };

    const sendForm = async () => {
        try {
            await instance.post(`/clubCaza/socio`, JSON.parse(body)).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Solicitud enviada correctamente',
                    showConfirmButton: true
                })
            });
        } catch (error) {
            console.warn(error);
        }
    }

    const sendEmail = async (paymentResponse) => {
        paymentResponse.email = JSON.parse(body).email;
        try{
            await instance.post(`/clubcaza/mailTransaction`, paymentResponse);
        }catch(error){
            console.warn(error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Error al enviar el email. Contacta con soporte con los datos vistos en pantalla.",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: "Aceptar"
                })
        }
    }




    return (<><div>Done</div></>);
}