import { Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import sorteosgratuitos from '../../assets/sorteos_tierra_caza_nuevo.jpg';
import sorteosexclusivos from '../../assets/sorteos-exclusivos.svg';
import sorteosreserva from '../../assets/sorteo_reserva_nuevo.jpg';
import missorteos from '../../assets/mis-sorteos.svg';
import { instanceWP } from "../../utils/axios-config";
import { useAuth } from "../../firebase/Auth";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";


export const Raffles = () => {

    const [loading, setLoading] = useState(false);
    const [rTypes, setRTypes] = useState();
    const { currentUser } = useAuth();
/*
    useEffect(() => {
        loadPageData();
    }, []);


    const sortTypes = (types) => {
        return types.sort(function(a, b) {
            if(a.toLowerCase() < b.toLowerCase()) return 1;
            if(a.toLowerCase() > b.toLowerCase()) return -1;
            return 0;
        });
    }

    const loadPageData = async () => {
        await instanceWP.options('/sorteo').then(function(resp) {
            let sortedTypes = sortTypes(Object.keys(resp.data.schema.properties.acf.properties.tipo_de_sorteo.items.enum));
            setRTypes(sortedTypes);
        })
        .catch(function(resp) {
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        }).finally(() => {
            setLoading(false);
        });
    }*/

    return (
        <>
        {loading ? (
            <Box p={3} align="center">
                <CircularProgress />
            </Box>
        ) : (
        <div className="cdc-blog-types">
            {currentUser.tipo?.toLowerCase() === "premium" && 
                (
                    <Link to="/mis-sorteos/">
                        <Grid className="cdc-home-noticias">
                        <figure>
                            <img src={missorteos} alt={""}/>
                        </figure>
                        <h1 className="cdc-raffles-heart">Mis sorteos</h1>
                        <p>Desde esta sección podrás consultar todos los sorteos en los que participas
                             y en que situación se encuentran, para que tenga toda la información de forma directa y en cualquier momento.
                        </p>
                        </Grid>
                    </Link>
                )
            }
            {/*rTypes ? (
                rTypes.map((type, index) => {
                    return (
                        <Link to={"/sorteos/" + (type.toLowerCase() === "sorteo tierra de caza" ? "tierraDeCaza" : type.toLowerCase() === "sorteo exclusivo" ? "exclusivo" :  "reserva")} key={"raffle-" + index}>
                            <Grid className="cdc-home-noticias">
                                <figure>
                                    {type.toLowerCase() === "sorteo tierra de caza" && 
                                        <img src={sorteosgratuitos} alt={"Imagen sorteo gratuito"}/>
                                    }
                                    {type.toLowerCase() === "sorteo exclusivo" && 
                                        <img src={sorteosexclusivos} alt={"Imagen sorteo exclusivo"}/>
                                    }
                                    {(type.toLowerCase() === "sorteo reserva" || type.toLowerCase() === "sorteo de reserva") && 
                                        <img src={sorteosreserva} alt={"Imagen sorteo reserva"}/>
                                    }
                                    </figure>
                            
                            <h1>{type.toLowerCase() === "sorteo reserva" ? "Sorteos en las Reservas" : type}</h1>
                            {type.toLowerCase() === "sorteo tierra de caza" &&
                                <p>
                                    Consulta el calendario de sorteos gratuitos de todo el año y 
                                    si se encuentran abiertos o ya han sido adjudicados. Recuerda que participas de forma automática solo por ser socio.
                                </p>
                            }
                            {type.toLowerCase() === "sorteo exclusivo" &&
                                <p>
                                    Aquí te podrás consultar y inscribirte en los sorteos exclusivos según
                                    se vallan publicando. Te indicamos si estás en plazo y en que situación se encuentran.
                                </p>
                            }
                            {(type.toLowerCase() === "sorteo reserva" || type.toLowerCase() === "sorteo de reserva") &&
                                <p>
                                    Consulta el calendario de sorteos de la Oferta Pública de Caza y
                                    si está abierta la inscripción. Recuerda que salvo que nos indiques lo contrario participamos todos los socios.
                                </p>
                            }
                            </Grid>
                        </Link>
                    )
                    })
                ) : (
                <div>No hay sorteos disponibles</div>
                )*/
                <>
                <Link to="/sorteos/tierraDeCaza" key={"raffle-gratuito"}>
                        <Grid className="cdc-home-noticias">
                        <figure>
                        <img src={sorteosgratuitos} alt={"Imagen sorteo gratuito"}/>
                        </figure>
                        <h1>Sorteos Tierra de Caza</h1>
                        <p>
                            Consulta el calendario de sorteos gratuitos de todo el año y 
                            si se encuentran abiertos o ya han sido adjudicados. Recuerda que participas de forma automática solo por ser socio.
                        </p>
                        </Grid>
                    </Link>
                    <Link to="/sorteos/exclusivo" key={"raffle-exclusivo"}>
                        <Grid className="cdc-home-noticias">
                        <figure>
                            <img src={sorteosexclusivos} alt={"Imagen sorteo exclusivo"}/>
                        </figure>
                        <h1>Oferta Directa</h1>
                        <p>
                            Aquí te podrás consultar y inscribirte en los sorteos exclusivos según
                            se vallan publicando. Te indicamos si estás en plazo y en que situación se encuentran.
                        </p>
                        </Grid>
                    </Link>
                    <Link to="/sorteos/reserva" key={"raffle-reserva"}>
                        <Grid className="cdc-home-noticias">
                        <figure>
                        <img src={sorteosreserva} alt={"Imagen sorteo reserva"}/>
                        </figure>
                        <h1>Sorteos en las Reservas</h1>
                        <p>
                            Consulta el calendario de sorteos de la Oferta Pública de Caza y
                            si está abierta la inscripción. Recuerda que salvo que nos indiques lo contrario participamos todos los socios.
                        </p>
                        </Grid>
                    </Link>
                    </>
                }
        </div>
        )
        }
    </>
        );
}