import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { instanceWP } from "../../../src/utils/axios-config";
import { format } from "date-fns";
import parse from "html-react-parser";
import { RRSS } from "../../components/rrss/RRSS";

export const BlogDetails = () => {

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [ blog, setBlog ] = useState();
    const [ categories, setCategories ] = useState();
    const [ error, setError ] = useState();

    useEffect(() => {
        loadBlogDetails(params.id);
    },[params]);

    const loadBlogDetails = async (id) => {
        try {
            await instanceWP.get(`/noticia/${id}?acf_format=standard`).then((response) => {
                let b;
                b = {...response.data.acf, title: response.data.title.rendered, noticia_categoria: response.data.noticia_categoria[0], date: response.data.date};
                return b;
            }).then(async (b) => {
                if(b.noticia_categoria){
                    await instanceWP.get(`/noticia_categoria`).then((cat) =>{
                        for (var i = 0; i < cat.data.length; i++) {
                            if(b.noticia_categoria === cat.data[i].id){
                                b.noticia_categoria = cat.data[i].name;
                                break;
                            }
                        }
                        setCategories(cat.data);
                        setBlog(b);
                    });
                }else{
                    setBlog(b);
                }
            });
                
        } catch (error) {
            console.warn(error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };
  
  return (
    <>
        <Box className="cdc-blog-details">
            <Grid className="cdc-blog-details-container" container justifyContent="center">
                {loading ? (
                    <Box p={3}>
                        <CircularProgress />
                    </Box>
                    )
                    : 
                    (
                        <>
                        {!error ?
                            <Grid item xs={12}>
                                {blog.image &&
                                <figure className="cdc-blog-details-img">
                                    <img src={blog.image ? blog.image : ""} alt={"Imagen " + blog.title}/>
                                </figure>
                                }
                                <p className="cdc-blog-date">{format(new Date(blog.date), "dd/MM/yyyy")}</p>
                                <Typography variant="h2" className="cdc-blog-details-title">{blog.title}</Typography>
                                <div className="cdc-blog-details-content">
                                    {parse(blog.content)}
                                </div>
                            </Grid>
                            :
                            <div>Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                        }
                        </>
                    )}
                    <RRSS />
            </Grid>
        </Box>
    </>
  );
}
