import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { SortingService } from '../../../services/SortingService';
import { FilteringService } from '../../../services/FilteringService';

const apiUrl = 'https://ulcbbyxid6.execute-api.eu-south-2.amazonaws.com/pro';

let numeroMensajes = 0;

const httpClient = (url, options = {}) => {
    options.headers = new Headers({ Accept: 'application/json' });
    options.headers.set('Authorization', 'Bearer ' + window.localStorage.getItem("tokenBackoffice"));
    return fetchUtils.fetchJson(url, options);
};

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file);
    });

export const DataProvider = {
    
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const query = {
            sort: params.sort,
            range: JSON.stringify([(page - 1) * perPage, page * perPage]),
            filter: params.filter,
        };
        let url;
        let result; 
        if(resource === "gestiones"){
            url =  `${apiUrl}/${resource}/allGestiones`;

            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"idGestion":').join('"id":'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"New"').join('"Nuevo"'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Pending"').join('"En Revisión"'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Close"').join('"Cerrada"'));
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
        
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,  
                    };
                });
        }else if(resource === "gestiones/nuevas"){
            url =  `${apiUrl}/gestiones/gestionesByStatus/New`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"idGestion":').join('"id":'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"New"').join('"Nuevo"'));
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
        
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,  
                    };
                });
            
            
        } else if(resource === "gestiones/pendientes"){
            url =  `${apiUrl}/gestiones/gestionesByStatus/Pending`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"idGestion":').join('"id":'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Pending"').join('"En Revisión"'));
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
        
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,  
                    };
                });
            
            
        }else if(resource === "gestiones/cerradas"){
            url =  `${apiUrl}/gestiones/gestionesByStatus/Close`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"idGestion":').join('"id":'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Close"').join('"Cerrada"'));
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
        
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,  
                    };
                });
        
        }else if(resource === "gestiones/allMessages"){
            url=`${apiUrl}/${resource}`;
            /*try{
                let storedPages = JSON.parse(window.localStorage.getItem("paginationInfo"));
                if(storedPages){
                    let p = storedPages.find((i) => { return i.page === params.pagination.page});
                    url = url + `&idThread=${p.idThread}&idGestion=${p.idGestion}&lastDate=${p.fechaCreacion}`;
                }
            }catch(error){

            }*/
            result = httpClient(url).then(
                ({ headers, json }) => {
                    let newjson = JSON.parse(JSON.stringify(json).split('"idThread":').join('"id":'));
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson.data, query, resource);
                    /*if(json.pagesInfo){
                        window.localStorage.setItem("paginationInfo", JSON.stringify(json.pagesInfo));
                        totalPages = Math.max(...json.pagesInfo.map(p => p.page));
                    }else if(window.localStorage.getItem("paginationInfo")){
                        totalPages = Math.max(...JSON.parse(window.localStorage.getItem("paginationInfo")).map(p => p.page));
                    }*/
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,
                        /*
                        data: newjson.data?.Items ? (newjson.data.Items.slice((page - 1) * perPage, page * perPage)) : (newjson.Items.slice((page - 1) * perPage, page * perPage)),
                        total: newjson.data?.Items ? (newjson.data.Items.length) : (newjson.Items.length),
                        */
                    };
                });
        }else if(resource === "sorteos"){
            url=`${apiUrl}/${resource}`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"pk":').join('"id":'));
                    newjson.Items.map(d => {
                        d.fechaFin = new Date(d.fechaFin.substring(4,6) + "/" + d.fechaFin.substring(6,8) + "/" + d.fechaFin.substring(0,4)).toISOString();
                        if(!d?.precio){
                            d.precio = "Sin precio"
                        }else{
                            d.precio = d.precio + " €";
                        }
                    });
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,  
                    };
            });

        }else if(resource === "documentos"){
            url=`${apiUrl}/${resource}/documentosList`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"idDocumento":').join('"id":'));
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson.data, query, resource);
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,
                    };
            });
        }else if(resource === "documentos/aprobados"){
            url=`${apiUrl}/documentos/documentosByStatus/Aprobado`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = {data: JSON.parse(JSON.stringify(json).split('"idDocumento":').join('"id":'))};
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,
                    };
            });

        }else if(resource === "documentos/revision"){
            //https://b8bxmklrd4.execute-api.eu-west-1.amazonaws.com/pre/documentos/documentosByStatus/:{Aprobado/Rechazado/Revision}
            url=`${apiUrl}/documentos/documentosByStatus/Revision`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = {data: JSON.parse(JSON.stringify(json).split('"idDocumento":').join('"id":'))};
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,
                    };
            });

        }else if(resource === "documentos/rechazados"){
            url=`${apiUrl}/documentos/documentosByStatus/Rechazado`;
            result = httpClient(url).then(
                ({ headers, json }) => {
                    // Hacer lo que sea con los datos
                    let newjson = {data: JSON.parse(JSON.stringify(json).split('"idDocumento":').join('"id":'))};
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);
                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,
                    };
            });

        }else{
            if(resource === "users"){
                url=`${apiUrl}/${resource}`;
                if(params.filter.estado !== ""){
                    query.filter.estado = params.filter.estado;
                }  
            }else if(resource === "users/premium"){
                if(params.filter.estado !== ""){
                    url=`${apiUrl}/users/usersByTipo/Premium/${params.filter.estado === "Revisión" ? "Revision" : params.filter.estado}`;
                }else{
                    url=`${apiUrl}/users`;
                    query.filter.tipo = "Premium";
                    query.filter.estado = params.filter.estado;
                }
            }else if(resource === "users/basicos"){
                if(params.filter.estado !== ""){
                    url=`${apiUrl}/users/usersByTipo/Basic/${params.filter.estado === "Revisión" ? "Revision" : params.filter.estado}`;
                }else{
                    url=`${apiUrl}/users`;
                    query.filter.tipo = "Basic";
                    query.filter.estado = params.filter.estado;
                }
            }

            result = httpClient(url).then(
                ({ headers, json }) => {

                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"pk":').join('"id":'));
                    newjson.data.map((n) =>{
                        if(n?.estado === "Revision"){
                            n.estado = "Revisión";
                            return n;
                        }
                        return n;
                    });
                    SortingService(newjson, query, resource);
                    newjson = FilteringService(newjson, query, resource);

                    return {
                        data: newjson.data.slice((page - 1) * perPage, page * perPage),
                        total: newjson.data.length,  
                        };
                    });
        }
                  
        return result;
    
    },

    getOne: async(resource, params) => {
        let result;
        if((resource === "gestiones")||(resource === "gestiones/nuevas")||(resource === "gestiones/pendientes")||(resource === "gestiones/cerradas")){
            result = await httpClient(`${apiUrl}/gestiones/gestionById/${params.id}`).then(
                ({ json }) => {
    
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"idGestion":').join('"id":'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"New"').join('"Nuevo"'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Pending"').join('"En Revisión"'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Close"').join('"Cerrada"'));
                    numeroMensajes = newjson.data.thread.length;
                    return{
                        data: newjson.data,
                    };
            });

        }else if(resource === "gestiones/allMessages"){
            result = await httpClient(`${apiUrl}/gestiones/gestionById/${params.id}`).then(
                ({ json }) => {
    
                    // Hacer lo que sea con los datos
                    let newjson = JSON.parse(JSON.stringify(json).split('"idGestion":').join('"id":'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"New"').join('"Nuevo"'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Pending"').join('"En Revisión"'));
                    newjson = JSON.parse(JSON.stringify(newjson).split('"Close"').join('"Cerrada"'));
                    numeroMensajes = newjson.data.thread.length;
                    return{
                        data: newjson.data,
                    };
            });
        }else if(resource === "sorteos"){
            result = httpClient(`${apiUrl}/${resource}/sorteo/${params.id}`).then( ({ json }) => {
    
                    // Hacer lo que sea con los datos
                    const newjson = JSON.parse(JSON.stringify(json).split('"pk":').join('"id":'));
                    let fechaFin = new Date(newjson.fechaFin.substring(4,6) + "/" + newjson.fechaFin.substring(6,8) + "/" + newjson.fechaFin.substring(0,4));
                    newjson.fechaFin = fechaFin.toISOString();
                    return{
                        data: newjson,
                    };
            });

        }else if(resource === "documentos" || resource === "documentos/revision" || resource === "documentos/rechazados" || resource === "documentos/aprobados"){
            result = httpClient(`${apiUrl}/documentos/documentoData/${encodeURIComponent(params.id)}`).then(
                ({ json }) => {
                    // Hacer lo que sea con los datos
                    const newjson = JSON.parse(JSON.stringify(json).split('"idDocumento":').join('"id":'));
                    if(newjson?.fechaCaducidad){
                        let caducidadString = newjson.fechaCaducidad;
                        let caducidadParts = caducidadString.split("/");
                        let caducidadObject = caducidadParts[2] + "-" + caducidadParts[1] + "-" + caducidadParts[0];
                        newjson.fechaCaducidad = caducidadObject;
                    }
                    
                    if(newjson?.fechaExpedicion){
                        let expedicionString = newjson.fechaExpedicion;
                        let expedicionParts = expedicionString.split("/");
                        let expedicionObject = expedicionParts[2] + "-" + expedicionParts[1] + "-" + expedicionParts[0];
                        newjson.fechaExpedicion = expedicionObject;
                    }

                    return{
                        data: newjson,
                    };
            });
        }else{
            result = await httpClient(`${apiUrl}/users/${encodeURIComponent(params.id)}`).then(
                ({ json }) => {
    
                    // Hacer lo que sea con los datos
                    const newjson = JSON.parse(JSON.stringify(json).split('"pk":').join('"id":'));
    
                    return{
                        data: newjson.data,
                    };
            });
        }

        return result;
    },
         
    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    create: async (resource, params) =>{
        let result;
        if(resource === "documentos" || resource === "documentos/revision" || resource === "documentos/rechazados" || resource === "documentos/aprobados"){
            let caducidadObject = undefined;
            let expedicionObject = undefined;
            if(params.data?.fechaCaducidad){
                let caducidadString = params.data.fechaCaducidad;
                let caducidadParts = caducidadString.split("-");
                caducidadObject = caducidadParts[2] + "/" + caducidadParts[1] + "/" + caducidadParts[0];
            }

            if(params.data?.fechaExpedicion){
                let expedicionString = params.data.fechaExpedicion;
                let expedicionParts = expedicionString.split("-");
                expedicionObject = expedicionParts[2] + "/" + expedicionParts[1] + "/" + expedicionParts[0];
            }
            
            //result = await convertFileToBase64(params.data.file.rawFile).then( async convertedBase => {
                //"formato": "" + params.data.file.rawFile.type.split("/").pop() + "",
                //"content": "" + convertedBase.split(",")[1] + "",

            let body = JSON.stringify({
                "idUsuario": "" + params.data.idUsuario + "",
                "tipo": "" + params.data.tipo + "",
                "formato": "" + params.data.file.formato + "",
                "content": "" + params.data.file.content + "",
                "fechaExpedicion": "" + expedicionObject + "",
                "fechaCaducidad": "" + caducidadObject + "",
                "nombre": "" + params.data.nombre + ""
                    
            });
            return await httpClient(`${apiUrl}/documentos/documento`, {
                method: 'POST',
                body: body,
            }).then(({ json }) => {
                return {data: { ...params.data, id: json.id }}
            })
            //});
        }
        if(resource === "users"){
            console.log(params);
            let body = JSON.stringify({

                "email": "" + params.data.id + "",
                "nombre": "" + params.data.nombre + "",
                "apellido": "" + params.data.apellido + "",
                "noTelefono": "" + params.data.nTelefono + "",
                "dni": "" + params.data.dni + "",
                "direccion": "" + params.data.direccion ? params.data.direccion : null + "",
                "poblacion": "" + params.data.poblacion ? params.data.poblacion : null + "",
                "provincia": "" + params.data.provincia ? params.data.provincia : null + "",
                "codigoPostal": "" + params.data.codigoPostal ? params.data.codigoPostal : null + "",
                "iban": "" + params.data.iban ? params.data.iban : null + "",
                "password": "" + params.data.password + "",
                "iviteCode": "" + params.data.promoCode ? params.data.promoCode : null + "",
            });
            //"fechaRenovacion": "" + new Date(params.data.fechaRenovacion).toISOString() + ""
            return await httpClient(`${apiUrl}/clubCaza/socioWeb`, {
                method: 'POST',
                body: body,
            }).then(({ json }) => {
                return {data: { ...params.data, id: json.id }}
            })
        }
        return result;
    },

    update: async (resource, params) =>{
        let result;
        if(resource === "gestiones"){
            let parametros = JSON.parse(JSON.stringify(params).split('"id":').join('"idGestion":'));
            result = await httpClient(`${apiUrl}/${resource}/newMesage/${params.id}`,{
            method: 'POST',
            body: JSON.stringify({
                                    "remitente" : "Admin",
                                    "idUsuario" :  "" + parametros.data.idUsuario + "",
                                    "mensaje" : "" + parametros.data.mensaje + ""
                                }),
            }).then(
                ({ json }) => {
                    const newjson = JSON.parse(JSON.stringify(json).split('"idGestion":').join('"id":'));
                    return{
                        data: newjson,
                    };
                });    
        }
        if(resource === "sorteos"){
            console.log(params);
            let parametros = JSON.parse(JSON.stringify(params).split('"id":').join('"pk":'));
            let listInscribers = parametros.data.usuarios.map((u) => {
                return u.pk;
            });
            result = await httpClient(`${apiUrl}/${resource}/subscribelist/${params.id}`,{
            method: 'POST',
            body: JSON.stringify(listInscribers),
            }).then(async ({ json }) => {
                return{
                    data: {"id": params.id}
                }
            });
        }else if(resource.includes("documentos")){
            let parametros = JSON.parse(JSON.stringify(params).split('"id":').join('"idDocumento":'));
            let expedicionObject = undefined;
            let caducidadObject = undefined;
            if(parametros.data.fechaCaducidad && parametros.data.fechaExpedicion && parametros.data.fechaCaducidad !== "undefined-undefined-undefined" && parametros.data.fechaExpedicion !== "undefined-undefined-undefined"){
                let caducidadString = parametros.data.fechaCaducidad;
                let caducidadParts = caducidadString.split("-");
                caducidadObject = caducidadParts[2] + "/" + caducidadParts[1] + "/" + caducidadParts[0];
                let expedicionString = parametros.data.fechaExpedicion;
                let expedicionParts = expedicionString.split("-");
                expedicionObject = expedicionParts[2] + "/" + expedicionParts[1] + "/" + expedicionParts[0];
            }
            result = await httpClient(`${apiUrl}/documentos/documentStatus`,{
            method: 'POST',
            body: JSON.stringify({
                    "idDocumento" : "" + parametros.data.idDocumento + "",
                    "idUsuario" :  "" + parametros.data.idUsuario + "",
                    "estado" : "" + parametros.data.estado + "",
                    "fechaExpedicion": "" + expedicionObject + "",
                    "fechaCaducidad": "" + caducidadObject + "",
                    "nombre": "" + parametros.data.nombre + ""
                }),
            }).then(async ({ json }) => {
                if(params.meta?.file.files.length > 0){
                    
                    let body = JSON.stringify({
                        "idUsuario": "" + parametros.data.idUsuario + "",
                        "tipo": "" + parametros.data.tipoDocumento + "",
                        "formato": "" + parametros.meta.file.files[0].formato + "",
                        "content": "" + parametros.meta.file.files[0].content + "",
                        "fechaExpedicion": "" + expedicionObject + "",
                        "fechaCaducidad": "" + caducidadObject + "",
                        "nombre": "" + parametros.data.nombre + ""
                            
                    });
                    return await httpClient(`${apiUrl}/documentos/documento`,{
                        method: 'POST',
                        body: body,
                    }).then(() => {
                        return{
                            data: params.data,
                        };
                    });
                }else{
                    return{
                        data: params.data,
                    };
                }
            }).finally(() =>{
                return{
                    data: params.data,
                };
            });
        }else{
            delete params.data['fechaModificacion'];
            let parametros = JSON.parse(JSON.stringify(params).split('"id":').join('"pk":'));
            result = await httpClient(`${apiUrl}/users/${params.id}`,{
            method: 'POST',
            body: JSON.stringify(parametros.data),
            }).then(
                ({ json }) => {
                    const newjson = JSON.parse(JSON.stringify(json).split('"pk":').join('"id":'));
                    return{
                        data: newjson.data,
                    };
                });
        }
        return result;
    },
    

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}/gestionById?${(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    delete: async (resource, params) =>{
        let result;
        if((resource === "gestiones")||(resource === "gestiones/nuevas")||(resource === "gestiones/pendientes")||(resource === "gestiones/cerradas")){
            result = await httpClient(`${apiUrl}/gestiones/closeGestion/${params.id}`, {
                method: 'POST',
                body: JSON.stringify({
                    "remitente" : "Admin",
                    "idUsuario" :  "" + params.previousData.idUsuario + "",
                    "mensaje" : "" + params.previousData.message + ""
                }),
                
            }).then(({ json }) => ({ data: json }));
        }
        if((resource === "users") || (resource === "users/basicos") || (resource === "users/premium")){
            result = await httpClient(`${apiUrl}/users/deleteUser/${params.id}`, {
                method: 'DELETE',
                
            }).then(({ json }) => ({ data: json }));
        }
        if((resource === "documentos" || resource === "documentos/revision" || resource === "documentos/aprobados" || resource === "documentos/rechazados")){
            result = await httpClient(`${apiUrl}/documentos/documento/${encodeURIComponent(params.id)}`, {
                method: 'DELETE',
                
            }).then(({ json }) => ({ data: json }));
        }
        return result;
    },
    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};