import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import SearchComponent from "../../../components/public-components/search/Search";
import JoinIn from "../../../components/public-components/join-in/JoinIn";
import Contact from "../../../components/public-components/contact/Contact";
import { useParams } from "react-router-dom";
import Related from "../../../components/public-components/related/Related";
import { RRSS } from "../../../components/public-components/rrss/RRSS";
import { useEffect, useState,Component  } from "react";
import { instanceWP } from "../../../utils/axios-config";
import parse from "html-react-parser";
import { useSearchParams } from "react-router-dom";
import { useNavigate,useLocation,Route, Switch } from 'react-router-dom';
import HelmetMetaData from "../../../components/helmetMetaData/HelmetMetaData";
import {FacebookShareButton,FacebookIcon, WhatsappShareButton,WhatsappIcon, TwitterShareButton, TwitterIcon} from "react-share";
import {Helmet} from 'react-helmet'

export const PBlogDetails = () => {
    
    const params = useParams();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();


    const [loading, setLoading] = useState(true);
    const [ blog, setBlog ] = useState();
    const [ categories, setCategories ] = useState([]);
    const [ category, setCategory ] = useState();
    const [ text, setText ] = useState();
    const [ categorySearch, setCategorySearch ] = useState();
    const [ error, setError ] = useState(false);

    useEffect(() => {
        setCategorySearch(searchParams.get('categories') ? searchParams.get('categories') : "");
        setText(searchParams.get('text') ? searchParams.get('text') : "");
        loadBlogDetails(params.id);
    },[params, searchParams]);

    const loadBlogDetails = async (id) => {
        try {
            await instanceWP.get(`/noticia/${id}?acf_format=standard`).then((response) => {
                let b;
                b = {...response.data.acf, title: response.data.title.rendered, noticia_categoria: response.data.noticia_categoria[0]};
                return b;
            }).then(async (b) => {
                if(b.noticia_categoria){
                    await instanceWP.get(`/noticia_categoria`).then((cat) =>{
                        for (var i = 0; i < cat.data.length; i++) {
                            if(b.noticia_categoria === cat.data[i].id){
                                b.noticia_categoria = cat.data[i].name;
                                setCategory(cat.data[i]);
                                break;
                            }
                        }
                        setCategories(cat.data);
                        setBlog(b);
                    });
                }else{
                    setBlog(b);
                }

                //Sustituir
                /*if(b.noticia_categoria){
                    await instanceWP.get(`/noticia_categoria/${b.noticia_categoria}`).then((cat) =>{
                        b.noticia_categoria = cat.data.name;
                        setCategory(cat.data);
                        setBlog(b);
                    });
                }else{
                    setBlog(b);
                }*/
            });
                
        } catch (error) {
            console.warn(error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const searchBlogsData = (e, textInput, categoryInput) => {
        navigate('/blogs' + "?text=" + textInput + "&categories=" + categoryInput);
    };
  
  return (
    <>
        
        <Box className="public-blog-details">
            
            <Box className="public-blog-details-title">
                <Typography variant="h1">Blog / Noticias</Typography>
                <SearchComponent defaultValue={"Todas las categorias"} categories={categories} text={text} setText={setText} category={categorySearch} setCategory={setCategorySearch} searchBlogs={searchBlogsData}/>
            </Box>
            <Grid className="public-blog-details-container" container justifyContent="center">
                {loading ? (
                    <Box p={3}>
                        <CircularProgress />
                    </Box>
                    )
                    : 
                    (
                        <>
                        {!error ?
                            <Grid item xs={12}>
                                {blog.image &&
                                <figure>
                                    <img src={blog.image ? blog.image : ""} alt={"Imagen " + blog.title}/>
                                </figure>
                                }
                                <Link href={"/blogs" + "?categories=" + categorySearch}>
                                    <Typography className="public-blog-details-category">{blog.noticia_categoria}</Typography>
                                </Link>
                                <Typography variant="h2">{blog.title}</Typography>
                                <div className="public-blog-details-container-content">
                                    {parse(blog.content)}
                                </div>
                            </Grid>
                            :
                            <div>Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                        }
                        </>
                    )}
            </Grid>
        </Box>
        <div class="social-buttons">
        <FacebookShareButton
            url={window.location.href}
        ><FacebookIcon size={36} />
        </FacebookShareButton>
        <WhatsappShareButton
            url={window.location.href}
        ><WhatsappIcon size={36} />
        </WhatsappShareButton>
        <TwitterShareButton
            url={window.location.href}
        ><TwitterIcon size={36} />
        </TwitterShareButton>
        </div>
        <Related type={"noticia"} excludeId={params.id} catId={category?.id} text={text} category={categorySearch}/>
        <JoinIn />
        <Contact />
    </>
  );
}
