import { Navigate } from "react-router-dom";
import { useAuth } from "../firebase/Auth";

export const PrivateRoute = ({ children }) => {

    const { currentUser } = useAuth();

    if(!currentUser){ 
        return <Navigate to="/welcome" />;
    }
    return children;

}