import { Box, CssBaseline } from "@mui/material";
import StepperComponent from "../../components/forms/formpremium/Stepper";
import PStepOne from "../../components/forms/formpremium/PStepOne";
import PStepTwo from "../../components/forms/formpremium/PStepTwo";
import PStepThree from "../../components/forms/formpremium/PStepThree";
import { useAuth } from "../../firebase/Auth";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState} from "react";
import Swal from "sweetalert2";
import { instance } from "../../utils/axios-config";

export const PremiumForm = () =>{

    const { currentUser } = useAuth();
    
    const [ searchParams ] = useSearchParams();
    const [ position, setPosition ] = useState(0);
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();
    const [ stateForm, setStateForm ] = useState({
        email: currentUser.pk ? currentUser.pk : "",
        nombre: currentUser.nombre ? currentUser.nombre : "",
        apellido: currentUser.apellido ? currentUser.apellido : "",
        noTelefono: currentUser.nTelefono ? currentUser.nTelefono : "",
        dni: currentUser.dni ? currentUser.dni : "",
        direccion: currentUser.direccion ? currentUser.direccion : "",
        poblacion: currentUser.poblacion ? currentUser.poblacion : "",
        provincia: currentUser.provincia ? currentUser.provincia : "",
        codigoPostal: currentUser.codigoPostal ? currentUser.codigoPostal : "",
        iban: currentUser.iban ? currentUser.iban : "",
        iviteCode: ''
    });
    let pagoRealizado = false;
    let emailMandado = false;
    const body = window.localStorage.getItem("payment");
    const navigate = useNavigate();

    useEffect(() => {
        if(searchParams.get('Ds_MerchantParameters')){
            decodeSignature();
        }
      });

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const checkPayment = (pagado, paymentResponse) => {
        if(pagado === false){
            sendForm();
            sendEmail(paymentResponse);
        }
    }

    const decodeSignature =  () =>{
        let encode = searchParams.get('Ds_MerchantParameters') ? searchParams.get('Ds_MerchantParameters') : "";
        if(encode !== "" && encode !== undefined){
             let decode = JSON.parse(window.atob(encode));
             if(decode){
                    setResponse(decode);
                    setLoading(false);
                    if(decode.Ds_Response === "0000"){
                        checkPayment(pagoRealizado, decode);
                        navigate('/payment', {state: decode});
                        pagoRealizado = true;
                    }else{
                        if(emailMandado === false){
                            sendEmail(decode);
                            navigate('/payment', {state: decode});
                            emailMandado = true;
                        }  
                    }
             }
        }
    };

    const sendForm = async () => {
        try {
            await instance.post(`/clubCaza/socio`, JSON.parse(body)).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Solicitud enviada correctamente',
                    showConfirmButton: true
                })
            });
        } catch (error) {
            console.warn(error);
        }
    }

    const sendEmail = async (paymentResponse) => {
        paymentResponse.email = JSON.parse(body).email;
        try{
            await instance.post(`/clubcaza/mailTransaction`, paymentResponse);
        }catch(error){
            console.warn(error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Error al enviar el email. Contacta con soporte con los datos vistos en pantalla.",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: "Aceptar"
                })
        }
    }

    return(
        <>
            <StepperComponent position={position}/>
            <Box className="cdc-joinclub" justifyContent="center">
                <CssBaseline />
                <Box>
                    <Box className="cdc-joinclub-title-container" textAlign="center">
                        <h1 className="cdc-joinclub-title">
                            Hacerme socio
                        </h1>
                        <p>Precio PVP <strong>99.00€</strong> Suscripción anual</p>
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <PStepOne position={position} setPosition={setPosition} state={stateForm} setState={setStateForm}/>
                        <PStepTwo position={position} setPosition={setPosition} state={stateForm} setState={setStateForm}/>
                        <PStepThree position={position} setPosition={setPosition} state={stateForm} setState={setStateForm}/>
                    </Box>
                </Box>
            </Box>
        </>
    );
}