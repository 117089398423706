import { Box, CircularProgress, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Join } from "../../components/join/Join";
import { useAuth } from "../../firebase/Auth";
import { instance } from "../../utils/axios-config";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DocumentDetail = () => {

    const params = useParams();
    const { currentUser, sessionToken } = useAuth();
    
    const [file, setFile] = useState();
    const [typeFile, setTypeFile] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({numPages, pageNumber}) => {
        if((pageNumber > numPages) || (pageNumber === undefined)){
            setPageNumber(1);
        }
        setNumPages(numPages);
    }

    const LoadingPdf = () => {
        return(
            <div>Cargando el PDF...</div>
        );
    }

    useEffect(() => {
        if(currentUser.tipo.toLowerCase() === "premium"){
            loadMyDocData(params.id);
        }
    }, [params]);

    const loadMyDocData = useCallback( async (id) => {
        setLoading(true);
        let encodeId = encodeURIComponent(id);
        instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        try{
            await instance.get(`/documentos/documento/${encodeId}`).then(function(resp) {
                try{
                    let arr = id.split("/"); 
                    let txt = arr[arr.length -1];
                    let arrTxt = txt.split(".");
                    setTypeFile(arrTxt[arrTxt.length -1]);
                }catch(error){
                }
                /*const urlSplitted = id.split(".");
                const type = urlSplitted[urlSplitted.length - 1];
                const buildSrc = "data:" + ((type === "png" || type === "jpeg") ? "image/" + type : "application/" + type) + ";base64," + resp.data.file;*/
                setFile(resp.data);
            });
        }catch(error){
            setError(true);
        }finally{
            setLoading(false);
        }
    }, []);

    return (
        <>
            {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Documentación"}/> : 
            <>
                {loading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid className='cdc-documents'>
                            {file && typeFile === "pdf" &&
                                <>
                                    <div>
                                        <Document file={file} loading={LoadingPdf} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => console.log("Inside Error", error)} >
                                            <Page pageNumber={pageNumber} renderTextLayer={false}/>
                                        </Document>
                                    </div>
                                    <p style={{fontWeight: 600}}>
                                        Página {pageNumber} de {numPages}
                                    </p>
                                    {numPages > 1 &&
                                        <div className="flex-container" style={{marginTop:"20px", justifyContent: "center"}}>
                                            
                                            {pageNumber > 1 &&
                                                <button class="pdf-button prev" onClick={() => {
                                                    if(pageNumber > 1){
                                                        setPageNumber(pageNumber - 1);
                                                    }
                                                }}>
                                                    Página anterior
                                                </button>
                                            }
                                            {numPages > pageNumber &&
                                                <button class="pdf-button next" onClick={() => {
                                                    if(numPages > pageNumber){
                                                        setPageNumber(pageNumber + 1);
                                                    }
                                                }}>
                                                    Página siguiente
                                                </button>
                                            }
                                        </div>
                                    }
                                    
                                    {/*<iframe className="cdc-documents-iframe-pdf" src={file} width="100%" height="100%"></iframe>*/}
                                </>
                            }
                            {file && typeFile !== "pdf" &&
                                <figure className="cdc-documents-image">
                                    <img alt="Imagen Documento" src={file}/>
                                </figure>
                            }
                        </Grid>
                    )
                }
            </>
            }
        </>
    );
}