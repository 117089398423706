import { Box } from "@mui/material";
import { useState } from "react";
import { instanceAWS } from "../../../src/utils/axios-config";
import whatsapp from "../../assets/chat-whatsapp.svg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebase/Auth";

export default function Contact() {

    const { currentUser } = useAuth();

    const [name, setName] = useState(currentUser?.nombre ? currentUser.nombre : '');
    const [phone, setPhone] = useState(currentUser?.nTelefono ? currentUser.nTelefono : '');
    const [email, setEmail] = useState(currentUser?.pk ? currentUser.pk : '');
    const [message, setMessage] = useState('');
    const [surname, setSurname] = useState(currentUser?.apellido ? currentUser.apellido : '');
    const [errors, setErrors] = useState({});
    const [isPending, setIsPending] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            sendForm();
        }
    };

    const validateForm = () =>{
        let valid = true;
        if(name === undefined || name === ''){
            setErrors(errors => ({...errors, errorName: true, errorNameMsg: "El nombre no debe estar vacío."}));
            valid = false;
        }
        if(surname === undefined || surname === ''){
            setErrors(errors => ({...errors, errorSurname: true, errorSurnameMsg: "Los apellidos no deben estar vacíos."}));
            valid = false;
        }
        if(email === undefined || email === ''){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no debe estar vacío."}));
            valid = false;
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es válido."}));
            valid = false;
        }
        if(phone === undefined || phone === ''){
            setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: "El teléfono no debe estar vacío."}));
            valid = false;
        }else if(!/^[0-9]{9}$/i.test(phone)){
            setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: "El teléfono debe contener 9 dígitos."}));
            valid = false;
        }
        if(message === undefined || message === ''){
            setErrors(errors => ({...errors, errorMessage: true, errorMessageMsg: "El mensaje no puede estar vacío."}));
            valid = false;
        }

        if(!valid){
            window.scrollTo({top:0,left:0, behavior: "smooth"});
        }

        return valid;
    }


    const sendForm = async () => {
        const body = {
            idUsuario: email,
            nombre: name,
            apellido: surname,
            nTelefono: phone,
            tipoGestion: "Contacto",
            titulo: "Contacto",
            mensaje: message
        };

        setIsPending(true);

        try {
            await instanceAWS.post(`/contacto/`, body).then(() => {
                setIsPending(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Consulta Enviada correctamente',
                    showConfirmButton: true
                }).then(() => {
                    navigate("/home");
                })
            });
        } catch (error) {
            console.warn(error);
            setIsPending(false);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Error en la petición. vuelve a intentarlo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
              })
        }
    }

    return(
            <Box className="cdc-contact" justifyContent="center">
                <Box textAlign="center">
                    <h1 className="cdc-contact-title">Contacte con nosotros mediante mail o por whatsapp</h1>
                    <figure className="cdc-contact-figure">
                        <a href="https://api.whatsapp.com/send?phone=34623124224&text=Hola%2C%20tengo%20tu%20informaci%C3%B3n%20de%20WhatsApp%20de%20tu%20web.">    
                        
                            <img src={whatsapp} alt="Imagen de enlace al chat de Whatsapp"/>
                        </a>
                    </figure>  
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    {errors && 
                        <Box className="cdc-contact-errors">
                            {errors.errorName && <span style={{color: "red"}}>{errors.errorNameMsg}</span>}
                            {errors.errorSurname && <span style={{color: "red"}}>{errors.errorSurnameMsg}</span>}
                            {errors.errorPhone && <span style={{color: "red"}}>{errors.errorPhoneMsg}</span>}
                            {errors.errorEmail && <span style={{color: "red"}}>{errors.errorEmailMsg}</span>}
                            {errors.errorMessage && <span style={{color: "red"}}>{errors.errorMessageMsg}</span>}
                        </Box>
                    }
                    <Box textAlign="center">
                        <div className="cdc-contact-input">
                            <label htmlFor="name">NOMBRE *</label>
                            <input type="text" id="name" name="name" placeholder="Escriba su nombre" value={name} onChange={(e) => setName(e.target.value)} required/>
                        </div>
                        <div className="cdc-contact-input">
                            <label htmlFor="surname">APELLIDOS *</label>
                            <input type="text" id="surname" name="surname" placeholder="Escriba sus apellidos" value={surname} onChange={(e) => setSurname(e.target.value)} required/>
                        </div>
                        <div className="cdc-contact-input">
                            <label htmlFor="phone">TELÉFONO *</label>
                            <input type="tel" id="phone" name="phone" 
                            value={phone} minLength="9" maxLength="9" pattern="[0-9]{9}" placeholder="Escriba su nº de teléfono" onChange={(e) => setPhone(e.target.value)} required/>
                        </div>
                        <div className="cdc-contact-input">
                            <label htmlFor="email">EMAIL *</label>
                            <input disabled type="email" id="email" name="email" placeholder="Escriba su correo electrónico" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        </div>
                        <div className="cdc-contact-input">
                            <label htmlFor="message">MENSAJE *</label>
                            <textarea id="message" name="message" placeholder="Escriba su mensaje" value={message} onChange={(e) => setMessage(e.target.value)} required/>
                        </div>
                        { !isPending && <button
                            className="cdc-contact-button"
                            type="submit"
                        >
                            Enviar
                        </button>}
                        { isPending && <button className="cdc-contact-button" disabled={true}>Enviando...</button>}
                    </Box>
                </Box>
            </Box>
    );
}