import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export default function PreviewDoc({open, setOpen, document, type}){

    const handleClose = () => {
        setOpen(false);
    }
    
    return(
        <Dialog open={open} onClose={handleClose} className="cdc-mensaje-attach-modal">
            <DialogContent>
                <Box>
                    <DialogTitle sx={{paddingLeft:"0"}}>Archivo:</DialogTitle>
                    {document && type === "pdf" &&
                        <iframe className="cdc-mensaje-attach-pdf" src={document} width="100%" height="100%"></iframe>
                    }
                    {document && type !== "pdf" && 
                        <figure className="noMargin textAlign">
                            <img className="width100" src={document} alt="Previsualización del documento"/>
                        </figure>
                    }
                    <DialogActions sx={{justifyContent: "center"}}>
                        <Button className="cdc-mensaje-button" label="Volver" variant="contained" onClick={handleClose}>Volver</Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}