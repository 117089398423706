import {Box, TextField, Button, Stack, Link} from '@mui/material';
import Typography from '@mui/material/Typography';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { instanceAWS } from '../../../utils/axios-config';

export default function ForgetpasswdForm ({formData, setFormData}) {

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            sendForm();
        }
    };

    const validateForm = () =>{
        let valid = true;
        if(formData.email === undefined || formData.email === ''){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no debe estar vacío"}));
            valid = false;
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es válido"}));
            valid = false;
        }
        return valid;
    }

    const sendForm = async () => {
        try {
            await instanceAWS.post(`/restorePasswordRequest/`, formData).then(() => {
                navigate("/reestablecer-contrasena?email=" + formData.email);
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Error en la petición. vuelve a intentarlo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
              })
        }
    };

    return(
        <Box className='resetpwdF' component="form" onSubmit={handleSubmit} noValidate>
            <Typography component="h2">
            Ingrese su dirección de correo electrónico {<br/>}
            para recibir un correo con un {<strong>código</strong>} de{<br/>}reestablecimiento.
            </Typography>
            <TextField
            className='resetpwdF-tField'
            required
            variant="standard"
            fullWidth
            style={{position: 'fixed', marginTop: '15px', width: '92%'}}
            id="email"
            label="CORREO ELECTRÓNICO"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => {
                setFormData({
                    email: e.target.value,
                });
            }}
            value={formData.email}
            error={errors.errorEmail}
            helperText={errors.errorEmailMsg}
            />
            <Stack direction="column" width="100%">
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    >
                    Restablecer la contraseña
                </Button>
                <Box className='resetpwdF-cod'>
                    <Link href="/reestablecer-contrasena" variant="body2">
                        Ya tengo un código
                    </Link>
                </Box>
            </Stack>
        </Box>
    )
}