import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import Copyright from '../../components/copyright/Copyright'
import ResetpasswdForm from '../../components/forms/formrecoverpasswd/ResetpasswdForm';
import ResetCompleted from '../../components/forms/formrecoverpasswd/ResetCompleted';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';


export default function Resetpasswd() {

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [completed, setCompleted] = useState(false);

    return(
        <Container className='login-main' component="main">
            <CssBaseline />
            <Box sx={{
                    background: '#FFFFFF',
                    paddingTop: 8,
                    position: "relative",
                    height: "100%"
                }}
            >
            {completed ?
                <ResetCompleted />
            : 
                <ResetpasswdForm setCompleted={setCompleted} email={searchParams.get('email')}/>
            }
            </Box>
        </Container>
    );
}