import { Box, CircularProgress, Typography } from "@mui/material";
import { RichTextInput } from 'ra-input-rich-text';
import { Edit, SimpleForm, TextInput, DateInput, DateField, required, TextField, Button, Toolbar, SaveButton, DeleteWithConfirmButton, useRecordContext, useNotify, useRedirect, ReferenceManyField, Datagrid, ReferenceManyFieldView, DateTimeInput, useEditController} from 'react-admin';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { format } from "date-fns";
import parse from "html-react-parser";
import { ThreadDetail } from "./ThreadDetail";
import CloseTicket from "../dialogs/CloseTicket";


export const GestionesEdit= () => {

    const [ openMsgList, setOpenMsgList ] = useState(false);
    const [ openThread, setOpenThread ] = useState(false);
    const [ openConfirm, setOpenConfirm ] = useState(false);
    const [ ticket, setTicket ] = useState({});
    const [ thread, setThread ] = useState({});

    const handleOpenMsgList = () => {
        if(openMsgList){
            setOpenMsgList(false);
        }else{
            setOpenMsgList(true);
        }
    }

    const handleOpenThread = (record, thread) => {
        setTicket(record);
        setThread(thread);
        setOpenThread(true);
    }

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    }

    const ThreadList = () => {
        const record = useRecordContext();
        record.thread.sort((a,b) => {
            return new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime();
        });
        return record.thread.map((thr, index) => {
            return (<tr className="cdc-backoffice-msg" key={"message-" + index} onClick={() => handleOpenThread(record, thr)}>
                    <td className="cdc-backoffice-msg-data">
                        <p className="cdc-backoffice-msg-content-date">{format(new Date(thr.fechaCreacion), "dd/MM/yyyy HH:mm")}</p>
                    </td>
                    <td>
                        <p className="cdc-backoffice-msg-content-sender">{thr.idRemitente}</p>
                    </td>
                    
                    {thr.mensaje && <td className="cdc-backoffice-msg-summary">{parse(thr?.mensaje)}</td>}
            </tr>);
        });
    };

    const GestionEditToolbar = props => {
        const record = useRecordContext();
        return(<Toolbar {...props} sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <Button className="button-toolbar" variant="contained" size="large" label="Lista de Mensajes" title="Lista de Mensajes" onClick={handleOpenMsgList}><ListIcon/></Button>
                    {!(record.status.toLowerCase() === "cerrada") && <Button label="Cerrar Ticket" icon={<CloseIcon/>} className="cdc-backoffice-button-delete" onClick={handleOpenConfirm}/>}
                </Toolbar>);
    };

    const ButtonCloseTicket = () => {
        const record = useRecordContext();
        return (<CloseTicket open={openConfirm} setOpen={setOpenConfirm} ticket={record}/>);
    };

    const StatusTicket = () => {
        const record = useRecordContext();
        let estado = "revision";
        if(record.status.toLowerCase() === "nuevo"){
            estado = "nuevo";
        }else if(record.status.toLowerCase() === "cerrada"){
            estado = "cerrada";
        }
        return (<TextField className={"edicion-title-status " + estado} source="status" />);
    }

    return(
        <>
        {!openThread ?
            <>
                <Edit title="Edición de Tickets" mutationMode="pessimistic" sx={{marginLeft: '2%',marginRight : '10%'}}>
                    <SimpleForm toolbar={<GestionEditToolbar/>}>
                        <div className="edicion-title">
                            <StatusTicket />
                            <div>
                                <div>
                                    <TextField className="edicion-title-titulo" source="titulo" />
                                    <span style={{fontWeight:"500", fontSize:"1rem"}}>#</span>
                                    <TextField className="edicion-title-id" id="id-ticket" source="id"/>
                                </div>
                            </div>
                        </div>
                        <div className="edicion-ticket-inputs">
                            <div className="edicion-ticket-inputs-0">
                                <span style={{fontWeight:"500"}}>Fecha Creación: </span>
                                <DateField showTime={true} className="edicion-title-fecha" source="fechaCreacion" options={{ year: 'numeric', month: 'numeric', day: 'numeric' , hour: 'numeric', minute: 'numeric'}} />
                            </div>
                            
                            <div className="edicion-ticket-inputs-1">
                                <div>
                                <Typography variant="subtitle1" className="edicion-ticket-inputs-typo">Información del ticket:</Typography>
                                    <div className="edicion-ticket-inputs-1-2 row">
                                        <TextInput disabled className="cdc-backoffice-thread-textfield row" label="Remitente" source="idUsuario" sx={{flex: 1}}/>
                                        <TextInput disabled className="cdc-backoffice-thread-textfield row" label="Estado del Ticket" id="estado" source="status" validate={required()} />
                                    </div> 
                                </div>
                            </div>
                                <div className="edicion-ticket-inputs-1-2 row">
                                    <DateTimeInput sx={{width:"300px", marginRight: "2%"}} disabled label="Fecha Creación de Ticket" source="fechaCreacion" />
                                    <DateTimeInput sx={{width:"300px"}} disabled label="Fecha Último Mensaje" source="fechaModificacionGestion" />
                                </div>
                        </div>
                        {/*<div className="container-edit-ticket">
                            <TextInput className="cdc-backoffice-input-multiline" label="MENSAJE ORIGEN DE CONSULTA:" disabled source="thread[0].mensaje" multiline fullWidth />
                        </div>
                        <div className="container-edit-ticket-rich">
                            <RichTextInput label="Nuevo mensaje:" source="mensaje" validate={[required()]} sx={{width: "100%", height: "64px", display:"contents", marginLeft:"10%"}}/>
                        </div>*/}
                        {openMsgList && <>
                            <Typography variant="subtitle1" className="edicion-ticket-inputs-typo">Hilo de mensajes:</Typography>
                            <table className="cdc-backoffice-msg-list">
                                <tbody>
                                    <ThreadList />
                                </tbody>
                            </table>
                        </>}
                    </SimpleForm>
                    <ButtonCloseTicket />
                </Edit>
            </>
            :
            <>
                <ThreadDetail openMsgList={openMsgList} setOpenMsgList={setOpenMsgList} setOpen={setOpenThread} record={ticket} thread={thread} handleOpenThread={handleOpenThread}/>
            </>
        }
        </>
    );
};
