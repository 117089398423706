import { Grid, Button, Dialog, DialogContent, DialogActions, DialogContentText } from "@mui/material";
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useEffect, useState} from "react";
import newsletter from "../../assets/newsletter.svg";
import NewsLetterForm from "./NewsLetterForm";
import { instance } from "../../utils/axios-config";



export const Newsletter = () => {

    const [open, setOpen] = useState(false);
    const [ error, setError ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ accepted, setAccepted ] = useState(false);

    const handleFormSubmit = () => {
        let errors = false;
        setError(null);

        if ( !email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors = true;
            setError( 'Por favor, introduce un correo válido.' );
        }

        if(!errors){
            subscribe();
        }
    }

    const subscribe = async () => {
        setLoading(true);
        try{
            await instance.post(`/clubCaza/mailchimpsubscribe`, {email: email}).then(function(resp){
                setAccepted(true);
            });
        }catch(error){
            console.log(error);
        }finally{
            setLoading(false);
        }
    }

    useEffect(() => {
      }, []);

    const handleCloseCancel = (e) => {
        e.preventDefault();
        setOpen(false);
        setAccepted(false);
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };
  
    return (
        <>
            <Grid id="newsletter-home" className="newsletter-home">
                <figure>
                    <div className='container'>
                    <img src={newsletter} alt={"Sección Newsletter"}/>
                    <div className='container-text'>
                        <p>
                            SUSCRÍBETE A NUESTRA NEWS LETTER 
                        </p>
                        <p style={{fontWeight: "400"}}>
                            Y DISFRUTA DE TODAS LAS NOTICIAS DEL CLUB Y DEL SECTOR CINEGÉTICO
                        </p>
                        <Button className='btn' onClick={handleOpenDialog}>Enviar</Button>
                    </div>   
                    </div>  
                </figure>
            </Grid>
            <Dialog open={open} onClose={handleCloseCancel} className="newsletter-dialog">
                <DialogContent className="newsletter-dialog-content">
                    <DialogActions className="newsletter-dialog-close">
                        <a href="#" onClick={handleCloseCancel} variant="contained">X</a>
                    </DialogActions>
                    <>
                        <div className="newsletter-dialog-content-form">
                            <DialogContentText className="newsletter-dialog-content-title">
                                Suscríbete a la Newsletter
                            </DialogContentText>
                            <p className="newsletter-dialog-content-subtitle">Para poder recibir toda la información relacionada con el club, por favor, introduce los siguientes datos.</p>
                            <div className="newsletter-dialog-content-fields">
                                <label htmlFor="name">EMAIL*</label>
                                <input
                                    onChange={(event) => setEmail(event?.target?.value ?? '')}
                                    type="email"
                                    name="email"
                                    placeholder="Tu correo"
                                    className="mr-2"
                                />
                            </div>
                            {!loading && !accepted &&
                                <div className="newsletter-dialog-content-button">
                                    <button onClick={handleFormSubmit}>
                                        Suscribirme
                                    </button>
                                </div>
                            }
                            {accepted &&
                                <div className="newsletter-dialog-content-button">
                                    <button onClick={handleCloseCancel}>
                                        Cerrar
                                    </button>
                                </div>
                            }
                    
                        </div>
                        <div className="newsletter-dialog-form-info">
                            {loading &&
                                <div className="newsletter-dialog-form-inprocess">Enviando...</div>
                            }
                            {error &&
                                <div className="newsletter-dialog-form-error">{error}</div>
                            }
                            {accepted &&
                                <div className="newsletter-dialog-form-success" >¡Te has suscrito correctamente!</div>
                            }
                        </div>
                    </>
                    {/*<MailchimpSubscribe
                        url={ MAILCHIMP_URL }
                        render={ ( props ) => {
                            const { subscribe, status, message } = props || {};
                            return (
                            <NewsLetterForm
                                status={ status }
                                close={handleCloseCancel}
                                message={ message }
                                onValidated={ formData => subscribe( formData ) }
                            />
                            );
                        } }
                    />*/}
                </DialogContent>
            </Dialog>
        </>
      );
}