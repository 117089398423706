import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/copyright/Copyright'
import ForgetpasswdForm from '../../components/forms/formrecoverpasswd/ForgetpasswdForm';
import { useState } from 'react';
import { Link, Button } from '@mui/material';
import backA from "../../assets/backArrow.svg";
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SegmentIcon from '@mui/icons-material/Segment';


export default function Forgetpasswd() {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: ''
      });

    const navigateToLogin = () => {
        navigate('/login');
    }


    return(
        <Container className='login-main' component="main" >
            <CssBaseline />
            <Box sx={{
                    background: '#FFFFFF',
                    paddingTop: 8,
                    position: "relative",
                    height: "100%"
                }}
            >
                <div className='login-header'component="form">
                    <Box className='login-header-left'>
                        <ArrowBackIosNewIcon onClick={navigateToLogin} className='login-header-backicon'/>
                        <Typography component="h1" variant="h5" className="login-header-typography">
                            ¿Has olvidado tu contraseña?
                        </Typography>
                    </Box>
                    <SegmentIcon className='login-header-listicon'/>
                </div>
                <Box className='login-form'>
                    <ForgetpasswdForm formData={formData} setFormData={setFormData}/>
                </Box>
            </Box>
        </Container>
    );
}