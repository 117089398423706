import { onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { messaging } from './FirebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const Notification = () => {

    const [ notification, setNotification ] = useState();

    useEffect(() => {
        //requestToken(); // Este token hay que enviarlo al back

        /*onMessage(messaging, (payload) => {
            console.log("payload", payload);
            console.log("Cae en el onMessage");
            if(payload.notification){
                setNotification({title: payload?.notification?.title, body: payload?.notification?.body});   
            }else{
                setNotification({title: payload?.data?.default, body: payload?.data?.default});
            }
        });*/
        
    }, []);

    useEffect(() => {
        toast(notification?.title, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }, [notification]);

    /*const requestToken = () =>{
        return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
                console.log('Tu token de mensajería: ', currentToken);
                // Realizar cualquier otra acción con el token
            } else {
                console.log('No hay token de registro disponible.');
            }
        }).catch((err) => {
            console.log('Ha ocurrido un error al recuperar el token. ', err);
        });
    };*/

    return(
        <>
            <ToastContainer />
        </>
    );

}