import { Box, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import SearchComponent from "../../components/search/SearchComponent";
import { format } from "date-fns";
import { instanceWP } from "../../utils/axios-config";
import parse from "html-react-parser";
import PPagination from "../../components/pagination/Pagination";
import { Link, useSearchParams } from "react-router-dom";


export const RafflesPublic = (props) => {

    const [ searchParams ] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [raffles, setRaffles] = useState();
    const [rafflesInactives, setRafflesInactives] = useState();
    const [ totalPages, setTotalPages ] = useState(1);
    const [ posPage, setPosPage ] = useState(1);
    const [text, setText] = useState();
    const [ error, setError ] = useState(false);

    const loadPageData = useCallback (async (startPage = 1, text) => {
        let buildUrl = `/sorteo?per_page=8&page=${startPage}&sorteo_categoria=${props?.categoryId}&acf_format=standard`;
        buildUrl = buildUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);
        
        await instanceWP.get(buildUrl).then(function(resp) {
            if(error) setError(false);
            sortAndMarkRaffles(resp);
            setTotalPages(parseInt(resp.headers['x-wp-totalpages']));
        })
        .catch(function(resp) {
            setError(true);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        }).finally(() => {
            setLoading(false);
            setPosPage(startPage);
            if (initialLoading) setInitialLoading(false);
        });
    }, [initialLoading,error]);

    const sortAndMarkRaffles = (raffles) =>{
        let rafflesActives = [];
        let rafflesInactives = [];
        let today = new Date();
        let todayFormated=today.getDate() + "/"+ parseInt(today.getMonth()+1) +"/"+today.getFullYear();

        raffles.data.map((r) => {
            try{
                let startDate = r.acf.fecha_de_inicio.split("/");
                let endDate = r.acf.fecha_fin.split("/");
                let todayDate = todayFormated.split("/");

                let start = new Date(startDate[2], parseInt(startDate[1])-1, startDate[0]);  // -1 because months are from 0 to 11
                let end   = new Date(endDate[2], parseInt(endDate[1])-1, endDate[0]);
                let actualDate = new Date(todayDate[2], parseInt(todayDate[1])-1, todayDate[0]);

                if(actualDate >= start && actualDate <= end){
                    rafflesActives.push(r);
                }else{
                    rafflesInactives.push(r);
                }
            }catch(error){
                rafflesInactives.push(r);
            }
        });
        setRaffles(rafflesActives);
        setRafflesInactives(rafflesInactives);
    } 

    useEffect(() => {
        setText(searchParams.get('text') ? searchParams.get('text') : "");
        loadPageData(1, text);
    }, [searchParams, loadPageData]);

    return (
        <>
            <div className="cdc-raffles">
                <SearchComponent text={text} setText={setText} search={loadPageData} setLoading={setLoading}/>
                            
            
                {initialLoading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {loading ? (
                                <Box p={3} align="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    {!error ?
                                        <>
                                        {raffles.length > 0 &&
                                            <ul className="cdc-raffles-list">
                                                {raffles.map((newElement, index) => {
                                                    return (
                                                        <div key={"raffle-" + index}>
                                                            <li className="cdc-raffles-container-first" >
                                                                {newElement.acf.image &&
                                                                    <figure>
                                                                        <img src={newElement.acf.image} alt={"Imagen " + newElement.title.rendered}/>
                                                                    </figure>
                                                                }
                                                                <Box className="cdc-raffles-container-first-box">
                                                                    <div style={{display: "flex", flexDirection: "row"}}>
                                                                        <p className="cdc-raffles-date" style={{marginRight: "10px"}}>Celebración: {newElement.acf.fecha_fin}</p>
                                                                        <p className="cdc-raffles-date">Precio: {newElement.acf.precio === "0" ? "Gratuito": newElement.acf.precio + " €"}</p>
                                                                    </div>
                                                                    <h2 className="cdc-raffles-title first">{newElement.title.rendered}</h2>
                                                                    <p className="cdc-raffles-summary first">{parse(newElement.acf.summary)}</p>
                                                                    <Link className="cdc-raffles-details-link" to={"/sorteo/" + newElement.id}>
                                                                        Ver detalles
                                                                    </Link>
                                                                </Box>
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        }
                                        {rafflesInactives.length > 0 && 
                                            <>
                                                <h2 className="cdc-raffles-previous">Sorteos anteriores</h2>
                                                {rafflesInactives.map((newElement, index) => {
                                                    return(
                                                        <div key={"raffle-pass-" + index}>
                                                            <Link className="cdc-raffles-list-link" to={"/sorteo/" + newElement.id}>
                                                                <li className="cdc-raffles-container">
                                                                    {newElement.acf.image &&
                                                                    <figure>
                                                                        <img src={newElement.acf.image} alt={"Imagen " + newElement.title.rendered}/>
                                                                    </figure>
                                                                    }
                                                                    <Box className="cdc-raffles-container-right">
                                                                        <p className="cdc-raffles-date">{format(new Date(newElement.date), "dd/MM/yyyy")}</p>
                                                                        <h2 className="cdc-raffles-title">{newElement.title.rendered}</h2>
                                                                        <p className="cdc-raffles-summary">{newElement.acf.summary}</p>
                                                                    </Box>
                                                                </li>
                                                            </Link>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        }
                                        {raffles.length === 0 && rafflesInactives === 0 && 
                                            <div className="cdc-raffles-notfound">No se han encontrado sorteos.</div>
                                        }
                                        </>
                                        :
                                        <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                                    }
                                </>
                            )
                            }
                        </>
                    )
                }
                <Box sx={{mb: 6, mt: 4}} align="center">
                    <PPagination setLoading={setLoading} loadData={loadPageData} totalPages={totalPages} text={text} category={null}/>
                </Box>
            </div>
        </>
    );
}