import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";

export default function PStepOne({position, setPosition, state, setState}) {

    const [errors, setErrors] = useState({});


    useEffect(() => {
    }, []);

    const nifValidation = (dni) => {
        let numero;
        let letr;
        let letra;
        let expresion_regular_dni;
        let result = false;
       
        expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
       
        if(expresion_regular_dni.test(dni) === true){
           numero = dni.substr(0,dni.length-1);
           letr = dni.substr(dni.length-1,1);
           numero = numero % 23;
           letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
           letra = letra.substring(numero,numero+1);
          if (letra===letr.toUpperCase()) {
             result = true;
           }
        }
        return result;
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(1);
        }
    }

    const validateForm = () =>{
        let valid = true;
        let inputIds = "";
        if(state.nombre === undefined || state.nombre === ''){
            setErrors(errors => ({...errors, errorName: true, errorNameMsg: ""}));
            inputIds = inputIds ? inputIds +",namejoinclub" : "namejoinclub";
            valid = false;
        }
        if(state.apellido === undefined || state.apellido === ''){
            setErrors(errors => ({...errors, errorSurname: true, errorSurnameMsg: ""}));
            inputIds = inputIds ? inputIds +",surnamejoinclub" : "surnamejoinclub";
            valid = false;
        }
        if(state.dni === undefined || state.dni === ''){
          setErrors(errors => ({...errors, errorDni: true, errorDniMsg: ""}));
          inputIds = inputIds ? inputIds +",dnijoinclub" : "dnijoinclub";
          valid = false;
        }else if(!nifValidation(state.dni)){
            setErrors(errors => ({...errors, errorDni: true, errorDniMsg: "El DNI no es válido."}));
            inputIds = inputIds ? inputIds +",dnijoinclub" : "dnijoinclub";
            valid = false;
        }
        if(inputIds !== ""){
          let elem = document.getElementById(inputIds.split(",")[0]);
          elem.scrollIntoView({behavior: "smooth"});
          elem.focus();
        }
        
        return valid;
    }
    

    return(
        <>
            <Box className={position !== 0 && 'hide'}>
                <div className="cdc-joinclub-input">
                    <label htmlFor="name">NOMBRE*</label>
                    <input type="text" id="namejoinclub" value={state.nombre} name="name" onChange={(e) => {setState(state => ({...state, nombre: e.target.value})); setErrors(errors => ({...errors, errorName: false, errorNameMsg: ""}));}} required className={errors.errorName ? "cdc-red-flag": ""}/>
                    {errors.errorName && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorNameMsg}</span>}
                </div>
                <div className="cdc-joinclub-input">
                    <label htmlFor="surname">APELLIDOS*</label>
                    <input type="text" id="surnamejoinclub" value={state.apellido} name="surname" onChange={(e) => {setState(state => ({...state, apellido: e.target.value})); setErrors(errors => ({...errors, errorSurname: false, errorSurnameMsg: ""}));}} required className={errors.errorSurname ? "cdc-red-flag": ""}/>
                    {errors.errorSurname && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorSurnameMsg}</span>}
                </div>
                <div className="cdc-joinclub-input">
                    <label htmlFor="dni">DNI*</label>
                    <input type="text" id="dnijoinclub" value={state.dni} name="dni" onChange={(e) => {setState(state => ({...state, dni: e.target.value})); setErrors(errors => ({...errors, errorDni: false, errorDniMsg: ""}));}} required className={errors.errorDni ? "cdc-red-flag": ""}/>
                    {errors.errorDni && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorDniMsg}</span>}
                </div>

                <Stack spacing={2} direction="row" justifyContent="end">
                    <button className="cdc-joinclub-next" onClick={(e) => handleNext(e)}>Siguiente</button>
                </Stack>
            </Box>
        </>
    );
}