import { Box, CircularProgress, Paper, Grid, Button } from "@mui/material";
import { useEffect, useState} from "react";
import licencias from "../../assets/licencias.svg";
import seguros from "../../assets/seguros.svg";
import newsletter from "../../assets/newsletter.svg";
import { Link } from "react-router-dom";
import { Newsletter } from "../../components/newsletter/NewsLetter";



export const Managements = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadManagementData();
      }, []);
    
    const loadManagementData = () => {
        setLoading(false);
    }
  
    return (
        <>
            {loading ? (
                <Box component={Paper} p={3}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className="cdc-managements">
                    <Link to="/licencias">
                        <Grid className="cdc-managements-licencias">
                        <figure>
                            <img src={licencias} alt={"Licencias"}/>
                        </figure>
                        <h1>Licencias de caza</h1>
                        <p>Desde esta sección puedes tramitar tus licencias de caza de forma rápida, eficaz y sencilla y sin ningún coste añadido. 
</p>
                        </Grid>
                    </Link>

                    <Link to="/seguros">
                        <Grid className="cdc-managements-seguros">
                        <figure>
                            <img src={seguros} alt={"Seguros"}/>
                        </figure>
                        <h1>Seguros</h1>
                        <p>Tramita o renueva tus seguros de caza tanto nacional como internacional sin costes adicionales. Nosotros nos encargamos de todo. </p>
                        </Grid>
                    </Link>

                    <Newsletter />
                </div>
            )
            }
        </>
      );
}