import {Box, TextField, Button, Stack, Typography, Link} from '@mui/material';
import { useState } from 'react';
import Swal from "sweetalert2";
import { instanceAWS } from '../../../utils/axios-config';
import backA from "../../../assets/backArrow.svg"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SegmentIcon from '@mui/icons-material/Segment';
import { useNavigate } from 'react-router-dom';


export default function ResetpasswdForm ({setCompleted, email}) {

    const [ formData, setFormData ] = useState({
        email: email ? email : "",
        password: "",
        passwordRpe: "",
        code: ""
    });
    const [ errors, setErrors ] = useState({});

    const navigate = useNavigate();

    const navigateToForget = () => {
        navigate('/olvido-contrasena');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            sendForm();
        }
    };

    const validateForm = () =>{
        let valid = true;
        if(formData.email === undefined || formData.email === ''){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no debe estar vacío"}));
            valid = false;
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es válido"}));
            valid = false;
        }
        if(formData.password === undefined || formData.password === ''){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña no puede estar vacía"}));
            valid = false;
        }else if(formData?.password.length < 3){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña debe ser de al menos 3 dígitos"}));
            valid = false;
        }
        if(formData.passwordRpe !== formData.password && (formData.password !== "" || formData.passwordRpe !== "")){
            setErrors(errors => ({...errors, errorPasswordRpe: true, errorPasswordRpeMsg: "Las contraseñas no coinciden"}));
            valid = false;
        }
        if(formData.code === undefined || formData.code === ""){
            setErrors(errors => ({...errors, errorCode: true, errorCodeMsg: "El código no puede estar vacío"}));
            valid = false;
        }else if(formData?.code.length !== 6){
            setErrors(errors => ({...errors, errorCode: true, errorCodeMsg: "El código debe contener 6 dígitos"}));
            valid = false;
        }
        return valid;
    }

    const sendForm = async () => {
        try {
            await instanceAWS.post(`/restorePassword/`, formData).then(() => {
                setCompleted(true);
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: error.response.status === 400 ? "Error en la petición. El código introducido no es correcto, vuelve a intentarlo." : "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
              })
        }
    };

    return(
        <>
            <Box className='login-header-left'>
                <ArrowBackIosNewIcon onClick={navigateToForget} className='login-header-backicon'/>
                <Typography component="h1" variant="h5" className="login-header-typography">
                    ¿Has olvidado tu contraseña?
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} noValidate className="resetpass-form-code">
                <Typography component="h2">Revise su dirección de correo electrónico para <br></br> localizar el {<strong>código</strong>} proporcionado.</Typography>
                <TextField
                className='resetpass-form-code-elemento'
                required
                variant="standard"
                fullWidth
                id="email"
                label="CORREO ELECTRÓNICO"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                    setFormData({
                        ...formData,
                        email: e.target.value,
                    });
                }}
                value={formData.email}
                error={errors.errorEmail}
                helperText={errors.errorEmailMsg}
                />
                <TextField
                className='resetpass-form-code-elemento'
                required
                variant="standard"
                fullWidth
                name="newpassword"
                label="NUEVA CONTRASEÑA"
                type="password"
                id="newpassword"
                autoComplete="new-password"
                onChange={(e) => {
                    setFormData({
                        ...formData,
                        password: e.target.value,
                    });
                }}
                value={formData.password}
                error={errors.errorPassword}
                helperText={errors.errorPasswordMsg}
                />
                <TextField
                className='resetpass-form-code-elemento'
                required
                variant="standard"
                fullWidth
                name="confirmnewpassword"
                label="CONFIRMAR NUEVA CONTRASEÑA"
                type="password"
                id="confirmnewpassword"
                autoComplete="confirm-new-password"
                onChange={(e) => {
                    setFormData({
                        ...formData,
                        passwordRpe: e.target.value,
                    });
                }}
                value={formData.passwordRpe}
                error={errors.errorPasswordRpe}
                helperText={errors.errorPasswordRpeMsg}
                />
                <TextField
                className='resetpass-form-code-elemento'
                required
                variant="standard"
                fullWidth
                name="code"
                label="CÓDIGO"
                type="code"
                id="code"
                autoComplete="code"
                onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        setFormData({
                            ...formData,
                            code: e.target.value,
                        });
                    }
                }}
                value={formData.code}
                error={errors.errorCode}
                helperText={errors.errorCodeMsg}
                inputProps={{ maxLength: 6 }}
                />
                <Stack direction="column" width="100%">
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    >
                    Reestablecer Contraseña
                    </Button>
                </Stack>
            </Box>
        </>
    )
}