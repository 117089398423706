import {CssBaseline, Box, Typography, Container, CircularProgress} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SegmentIcon from '@mui/icons-material/Segment';
import LoginForm from '../../components/forms/formlogin/LoginForm';
import { useEffect, useState } from 'react';
//import { messaging } from '../../firebase/FirebaseConfig';
import { useAuth } from '../../firebase/Auth';
import { getToken } from 'firebase/messaging';
import { useNavigate } from 'react-router-dom';

export default function Login() {

    const navigate = useNavigate();
    const { tokenMessage, setTokenMessage } = useAuth();
    const [ valueToken, setValueToken ] = useState();
    const [ loading, setLoading ] = useState(false);

    const navigateToWelcome = () => {
        navigate('/welcome');
    }

    useEffect(() => {
        //requestToken();
    }, []);

    /*const requestToken = () =>{
        setLoading(true);
        return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
                console.log('Tu token de mensajería: ', currentToken);
                // Realizar cualquier otra acción con el token
                setTokenMessage(currentToken);
            } else {
                console.log('No hay token de registro disponible.');
            }
        }).catch((err) => {
            console.log('Ha ocurrido un error al recuperar el token. ', err);
        }).finally(() => {
            setLoading(false);
        });
    };*/
    
    return(
            <Container component="main" className="login-main">
                <CssBaseline />
                <Box          
                sx={{
                    background: '#FFFFFF',
                    paddingTop: 8,
                    position: "relative",
                    height: "100%"
                }}
                >
                    <div className='login-header'component="form">
                        <Box className='login-header-left'>
                            <ArrowBackIosNewIcon onClick={navigateToWelcome} className='login-header-backicon'/>
                            <Typography component="h1" variant="h5" className="login-header-typography">
                                Iniciar Sesión
                            </Typography>
                        </Box>
                        <SegmentIcon className='login-header-listicon'/>
                    </div>
                    {loading ? (
                        <Box className="login-form-progress">
                            <CircularProgress />
                        </Box>
                    )
                    : 
                    (
                        <>
                            <LoginForm />
                            {valueToken && <div>{valueToken}</div>}
                            <button style={{position:"absolute",bottom:"0",background: "transparent",border:"0",fontSize: "0",width:"30px",height: "20px", outline: "none"}} onClick={() => {setValueToken(tokenMessage)}}>X</button>
                        </>
                    )}
                    </Box>
            </Container>
    );
}