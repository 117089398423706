import React from 'react'

/**
 * componente para envolver la etiqueta main
 * 
 * @returns {React.Component}
 */
export default function MainComponent({children}) {

    const width = 50;//dimensions.desktop.menuWidth;

    return (
        <main style={{width: `calc(100% - ${width}px)`, margin: "20px auto"}}>
            {children}
        </main>
    )
}
