import imageCompression from 'browser-image-compression'

export const validFileSize = (file) => {

    const MAX_FILE_SIZE = 2048; // 2MB

    const fileSizeKiloBytes = file.size / 1024;
    
    if(fileSizeKiloBytes > MAX_FILE_SIZE){
        return false;
    }else{
        return true;
    }
}

export const validFileType = (file) => {
    let fileType = null;
    let validType = false;
    try{
        fileType = file.type.split("/").pop();
        if(fileType === "pdf" || fileType === "jpg" || fileType === "png" || fileType === "jpeg"){
            validType = true;
        }
    }catch(error){
        validType = false;
    }finally{
        return validType;
    }
}

export const compressImageUpload = async (file) => {

    let result = null;
    const imageFile = file;
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    
    const options = {
        maxSizeMB: 2,
        useWebWorker: true,
    }
    const compressedFile = await imageCompression(imageFile, options);
    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    result = compressedFile;

    return result;
    
}