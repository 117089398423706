import { useLocation } from "react-router-dom";
import { useEffect} from "react";
import { useAuth } from "../../firebase/Auth";
import {instanceUsers} from "../../utils/axios-config";
import Swal from "sweetalert2";

export const PaymentInstalled = () => {

    const {state} = useLocation();
    const { currentUser, setCurrentUser } = useAuth();
    
    useEffect(() => {
        if(state.Ds_Response === "0000"){
            getUser();
        }
    }, []);

    const getUser = async () => {
        try {
            instanceUsers.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instanceUsers.get(`/${(currentUser.pk)}`).then((response) => {
                setCurrentUser(response.data.data);
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado al cargar la imagen de perfil",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });

        }
    }

    return (
        <>
            <div className="payment">
                {state.Ds_Response !== "0000" && state.Ds_Response !== undefined && 
                    <h1> Su pago ha sido rechazado, puede contactar con nuestro equipo de soporte si necesita ayuda.</h1>  
                }
                {state.Ds_Response === "0000" && 
                    <h1> Su pago ha sido autorizado. A continuación le dejamos la información de su cobro.</h1>
                }
                <h3>DATOS DE SU COBRO:</h3>
                <ul>
                    <li><strong>Fecha de Compra:</strong> {state.Ds_Date.replaceAll('%2F', '-')}</li>
                    <li><strong>Hora de Compra:</strong> {state.Ds_Hour.replace('%3A', ':')}</li>
                    <li><strong>Cantidad:</strong> {state.Ds_Amount.substring(0,state.Ds_Amount.length-2)+","+ state.Ds_Amount.substring(state.Ds_Amount.length-2)+"€"}</li>
                    <li><strong>Código de transacción:</strong> {state.Ds_Order}</li>
                </ul>
            </div>
        </>
    );
}