import { Box, Button, Link, Typography } from "@mui/material";

export default function ResetComplete() {

    return (
      <Box sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
      }}>
          <Typography component="h1" variant="h5" className="reg-complete-title">Cambio de contraseña completado</Typography>
          <Typography className="textoFinalC">¡Contraseña cambiada correctamente! Ya puedes acceder con tu nueva contraseña a la aplicación desde la página de Login.</Typography>
            <Link href="/login" className="reg-complete-button">
                Volver al Login
            </Link>
      </Box>
    )
};