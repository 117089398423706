import React from 'react';
import { Box, Link, Typography } from '@mui/material';


export default function RegisterComplete() {

      return (
        <Box sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Typography component="h1" variant="h5" className="reg-complete-title">Registro completado</Typography>
            <Typography>¡Te has registrado correctamente! Ya puedes acceder a la aplicación desde la página de Login.</Typography>
                    <Link href="/login" variant="body2" className="reg-complete-button">
                        Volver al Login
                    </Link>
        </Box>
      )
};
    

    
