import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";

export default function PStepTwo({position, setPosition, state, setState}) {
    const [errors, setErrors] = useState({});

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(2);
        }
    };
    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(0);
    }

    const validateForm = () =>{
        let valid = true;
        let inputIds = "";
        if(state.direccion === undefined || state.direccion === ''){
            setErrors(errors => ({...errors, errorAddress: true, errorAddressMsg: ""}));
            inputIds = inputIds ? inputIds +",addressjoinclub" : "addressjoinclub";
            valid = false;
        }
        if(state.poblacion === undefined || state.poblacion === ''){
            setErrors(errors => ({...errors, errorTown: true, errorTownMsg: ""}));
            inputIds = inputIds ? inputIds +",townjoinclub" : "townjoinclub";
            valid = false;
        }
        if(state.provincia === undefined || state.provincia === ''){
          setErrors(errors => ({...errors, errorProvince: true, errorProvinceMsg: ""}));
          inputIds = inputIds ? inputIds +",provincejoinclub" : "provincejoinclub";
          valid = false;
        }
        if(state.codigoPostal === undefined || state.codigoPostal === ''){
          setErrors(errors => ({...errors, errorZipcode: true, errorZipcodeMsg: ""}));
          inputIds = inputIds ? inputIds +",zipcodejoinclub" : "zipcodejoinclub";
          valid = false;
        }else if(!/^[0-9]{5}$/i.test(state.codigoPostal)){
            setErrors(errors => ({...errors, errorZipcode: true, errorZipcodeMsg: "El código postal debe tener 5 dígitos."}));
            inputIds = inputIds ? inputIds +",zipcodejoinclub" : "zipcodejoinclub";
            valid = false;
        }
        if(state.email === undefined || state.email === ''){
          setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: ""}));
          inputIds = inputIds ? inputIds +",emailjoinclub" : "emailjoinclub";
          valid = false;
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)){
          setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es un correo válido."}));
          inputIds = inputIds ? inputIds +",emailjoinclub" : "emailjoinclub";
          valid = false;
        }
        if(state.noTelefono === undefined || state.noTelefono === ''){
            setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: ""}));
            inputIds = inputIds ? inputIds +",phonejoinclub" : "phonejoinclub";
            valid = false;
        }else if(!/^[0-9]{9}$/i.test(state.noTelefono)){
            setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: "El teléfono debe contener 9 dígitos."}));
            inputIds = inputIds ? inputIds +",phonejoinclub" : "phonejoinclub";
            valid = false;
        }
        
        if(inputIds !== ""){
          let elem = document.getElementById(inputIds.split(",")[0]);
          elem.scrollIntoView({behavior: "smooth"});
          elem.focus();
        }
        
        return valid;
    }

    return(
        <>
            <Box className={position !== 1 && 'hide'}>
                <div className="cdc-joinclub-input">
                    <label htmlFor="address">DIRECCIÓN*</label>
                    <input type="text" id="addressjoinclub" value={state.direccion} name="address" onChange={(e) => {setState(state => ({...state, direccion: e.target.value})); setErrors(errors => ({...errors, errorAddress: false, errorAddressMsg: ""}));}} required className={errors.errorAddress ? "cdc-red-flag": ""}/>
                    {errors.errorAddress && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorAddressMsg}</span>}
                </div>
                <div className="cdc-joinclub-input">
                    <label htmlFor="town">POBLACIÓN*</label>
                    <input type="text" id="townjoinclub" value={state.poblacion} name="town" onChange={(e) => {setState(state => ({...state, poblacion: e.target.value})); setErrors(errors => ({...errors, errorTown: false, errorTownMsg: ""}));}} required className={errors.errorTown ? "cdc-red-flag": ""}/>
                    {errors.errorTown && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorTownMsg}</span>}
                </div>
                <div className="cdc-joinclub-input-inline">
                    <div className="">
                        <label htmlFor="province">PROVINCIA*</label>
                        <select name="province" id="provincejoinclub" value={state.provincia ? state.provincia : ""} onChange={(e) => {setState(state => ({...state, provincia: e.target.value})); setErrors(errors => ({...errors, errorProvince: false, errorProvinceMsg: ""}));}} className={errors.errorProvince ? "cdc-red-flag": ""}>
                            <option value="">{"-"}</option>
                            <option value="Álava/Araba">Álava/Araba</option>
                            <option value="Albacete">Albacete</option>
                            <option value="Alicante">Alicante</option>
                            <option value="Almería">Almería</option>
                            <option value="Asturias">Asturias</option>
                            <option value="Ávila">Ávila</option>
                            <option value="Badajoz">Badajoz</option>
                            <option value="Baleares">Baleares</option>
                            <option value="Barcelona">Barcelona</option>
                            <option value="Burgos">Burgos</option>
                            <option value="Cáceres">Cáceres</option>
                            <option value="Cádiz">Cádiz</option>
                            <option value="Cantabria">Cantabria</option>
                            <option value="Castellón">Castellón</option>
                            <option value="Ceuta">Ceuta</option>
                            <option value="Ciudad Real">Ciudad Real</option>
                            <option value="Córdoba">Córdoba</option>
                            <option value="Cuenca">Cuenca</option>
                            <option value="Gerona/Girona">Gerona/Girona</option>
                            <option value="Granada">Granada</option>
                            <option value="Guadalajara">Guadalajara</option>
                            <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                            <option value="Huelva">Huelva</option>
                            <option value="Huesca">Huesca</option>
                            <option value="Jaén">Jaén</option>
                            <option value="La Coruña/A Coruña">La Coruña/A Coruña</option>
                            <option value="La Rioja">La Rioja</option>
                            <option value="Las Palmas">Las Palmas</option>
                            <option value="León">León</option>
                            <option value="Lérida/Lleida">Lérida/Lleida</option>
                            <option value="Lugo">Lugo</option>
                            <option value="Madrid">Madrid</option>
                            <option value="Málaga">Málaga</option>
                            <option value="Melilla">Melilla</option>
                            <option value="Murcia">Murcia</option>
                            <option value="Navarra">Navarra</option>
                            <option value="Orense/Ourense">Orense/Ourense</option>
                            <option value="Palencia">Palencia</option>
                            <option value="Pontevedra">Pontevedra</option>
                            <option value="Salamanca">Salamanca</option>
                            <option value="Segovia">Segovia</option>
                            <option value="Sevilla">Sevilla</option>
                            <option value="Soria">Soria</option>
                            <option value="Tarragona">Tarragona</option>
                            <option value="Tenerife">Tenerife</option>
                            <option value="Teruel">Teruel</option>
                            <option value="Toledo">Toledo</option>
                            <option value="Valencia">Valencia</option>
                            <option value="Valladolid">Valladolid</option>
                            <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                            <option value="Zamora">Zamora</option>
                            <option value="Zaragoza">Zaragoza</option>
                        </select>
                        {errors.errorProvince && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorProvinceMsg}</span>}
                    </div>
                    <div className="">
                        <label htmlFor="zipcode">CÓDIGO POSTAL*</label>
                        <input type="text" id="zipcodejoinclub" value={state.codigoPostal} name="zipcode" onChange={(e) => {setState(state => ({...state, codigoPostal: e.target.value})); setErrors(errors => ({...errors, errorZipcode: false, errorZipcodeMsg: ""}));}} required className={errors.errorZipcode ? "cdc-red-flag": ""}/>
                        {errors.errorZipcode && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorZipcodeMsg}</span>}
                    </div>
                </div>
                <div className="cdc-joinclub-input">
                    <label htmlFor="email">EMAIL*</label>
                    <input type="text" id="emailjoinclub" value={state.email} name="email" disabled required className={errors.errorEmail ? "cdc-red-flag": ""}/>
                    {errors.errorEmail && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorEmailMsg}</span>}
                </div>
                <div className="cdc-joinclub-input">
                    <label htmlFor="phone">TELÉFONO*</label>
                    <input type="text" id="phonejoinclub" value={state.noTelefono} name="phone" onChange={(e) => {setState(state => ({...state, noTelefono: e.target.value})); setErrors(errors => ({...errors, errorPhone: false, errorPhoneMsg: ""}));}} required className={errors.errorPhone ? "cdc-red-flag": ""}/>
                    {errors.errorPhone && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorPhoneMsg}</span>}
                </div>

                <Stack spacing={2} direction="row" justifyContent="space-between">
                    <button className="cdc-joinclub-prev" onClick={(e) => handlePrev(e)}>Anterior</button>
                    <button className="cdc-joinclub-next" onClick={(e) => handleNext(e)}>Siguiente</button>
                </Stack>
            </Box>
        </>
    );
}