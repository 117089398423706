import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import logo from '../../assets/cdc-logo-footer.svg';
import { useNavigate } from 'react-router-dom';
 
export const Join = ({text}) => {

    const navigate = useNavigate();

    return(
        <Container className="cdc-join">
            <div className="cdc-join-card">
                <img src={logo} alt="Logo" />
                <h1>Premium</h1>
                <button className="public-section-member-button banner" onClick={() => navigate("/hacerse-socio")}>HAZTE SOCIO</button>
                <p style={{fontSize:"20px", fontWeight:"600"}}>
                    Date de alta como socio y accede a todos los servicios del club
                </p>
                <p>Si ya eres socio contacta con nosotros para ver el estado de tu suscripción</p>
                </div>    
        </Container>
    );
}