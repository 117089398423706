import { Box } from "@mui/material";

export default function SearchComponent({text, setText, search, setLoading}) {

    const changeText = (e) => {
        e.persist();
        setText(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        search(1, text);
    }


    return(
        <>
            <Box component="form" className="cdc-blog-search" onSubmit={handleSubmit}>
                <p>Buscador</p>
                <div>
                    <input type="submit" id="search-submit" value="" />
                    <input name="text" type="text" placeholder="" defaultValue={text} onChange={changeText}/>
                </div>
            </Box>
        </>
    );
}