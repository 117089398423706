import { CircularProgress, Grid, Button, Box} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../firebase/Auth';
import { instanceAWSbo } from "../../../src/utils/axios-config";
import seguros from '../../assets/insurance.svg';
import { Join } from '../../components/join/Join';
import Swal from 'sweetalert2';

export const Insurance = () => {

    const { currentUser } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendForm();
    }

    const sendForm = async () => {
        const body = {
            idUsuario: currentUser.pk,
            nombre: currentUser.nombre,
            apellido: currentUser.apellido,
            nTelefono: currentUser.nTelefono,
            tipoGestion: "Seguro",
            titulo: "Solicitud Seguro",
            mensaje: "Buenos días, Me gustaría que el club me tramitara mi seguro de caza para este nuevo año"
        };

        try {
            await instanceAWSbo.post(`/newGestion/`, body).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Consulta Enviada correctamente',
                    showConfirmButton: true,
                    timer: 1500
                })
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Error en la petición. vuelve a intentarlo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
              })
        }
    }

    return (
        <>
        {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Seguros"}/> :
            <Grid className='cdc-license'>
                <figure>
                    <img src={seguros} alt={"Seguros"}/>
                </figure>
                <Grid className='cdc-license-userT'>
                    <h1>Seguro de caza</h1>
                    
                    <p>NOMBRE</p>
                    <h2>{currentUser.nombre}</h2>

                    <p>APELLIDOS</p>
                    <h2>{currentUser.apellido}</h2>

                    <p>EMAIL</p>
                    <h2>{currentUser.pk}</h2>

                    <Box className='cdc-insurance' component="form" onSubmit={handleSubmit} noValidate>
                        <Button className='cdc-insurance-btn' type='submit'>Enviar solicitud de Seguro</Button>
                    </Box>
                </Grid>
            </Grid>
        }
        </>
    );
    
}