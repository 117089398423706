import linkedin from "../../assets/linkedinegative.svg";
import twitter from "../../assets/twitternegative.svg";
import facebook from "../../assets/facebooknegative.svg";
import instagram from "../../assets/instagramnegative.svg";
import { Container, Link } from "@mui/material";

export const RRSS = () => {

    return(
        <Container className="cdc-rrss">
            <Link href="https://es.linkedin.com/company/club-tierra-de-caza/">
                <figure>
                    <img src={linkedin} alt="Imagen enlace a LinkedIn"/>
                </figure>
            </Link>
            <Link href="https://twitter.com/tierradecaza">
                <figure>
                    <img src={twitter} alt="Imagen enlace a Twitter"/>
                </figure>
            </Link>
            <Link href="https://es-es.facebook.com/clubtierradecaza/">
                <figure>
                    <img src={facebook} alt="Imagen enlace a Facebook"/>
                </figure>
            </Link>
            <Link href="https://www.instagram.com/clubtierradecaza/">
                <figure>
                    <img src={instagram} alt="Imagen enlace a Instagram"/>
                </figure>
            </Link>
        </Container>
    );
}