import { Box, Button, CircularProgress, TextField, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../firebase/Auth";
import { instance, instanceUsers} from "../../utils/axios-config";
import Swal from "sweetalert2";
import candado from "../../assets/candado.svg";
//import perfil from "../../assets/profiledefault.svg";
import perfil from "../../assets/mobile/default-hunter.png";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { compressImageUpload, validFileSize, validFileType } from "../../services/Commons";

export const Profile = () => {

    const { currentUser, setCurrentUser } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loadingCompress, setLoadingCompress] = useState(false);
    const [ change, setChange ] = useState(false);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [photo, setPhoto] = useState();
    const [formData, setFormData] = useState({
        nombre: currentUser.nombre,
        apellido: currentUser.apellido,
        pk: currentUser.pk
    });
    const [formDataPremium, setFormDataPremium] = useState({
        nombre: currentUser.nombre,
        apellido: currentUser.apellido,
        pk: currentUser.pk,
        provincia: currentUser.provincia,
        poblacion: currentUser.poblacion,
        direccion: currentUser.direccion,
        nTelefono: currentUser.nTelefono,
        codigoPostal: currentUser.codigoPostal,
        iban: currentUser.iban,
        promoCode: currentUser.promoCode,
        tipo: currentUser.tipo,
        fechaModificacion: currentUser.fechaModificacion ,
        nTelefono:currentUser.nTelefono ,
        nroSocio: currentUser.nroSocio ,
        sk: currentUser.sk ,
        puntos: currentUser.puntos ,
        fechaCreacion: currentUser.fechaCreacion ,
        dni: currentUser.dni ,
        
    });
    const [formDataReset, setFormDataReset] = useState({
        email: currentUser.pk,
        oldPassword: "",
        newPassword: ""
    });
    
    const countInput = useRef();

    useEffect(() => {
        loadPageData();
        if(currentUser.profile !== undefined){
            getProfilePic();
        }
    }, []);

    const loadPageData = async () => {
        setLoading(false);
    }

    const changeToInvitacion = () => {
        navigate('/invitar-amigo');
    }

    const changeToForm = () => {
        setErrors({});
        setLoadingCompress(false);
        countInput.current.innerText = parseInt(countInput.current.innerText) + 1;
        window.scrollTo({top:0,left:0, behavior: "smooth"})
        setChange(!change);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseCancel = () => {
        setOpen(false);
    };

    const handleSubmitUpdateUser = async(e) => {
        e.preventDefault();
        setErrors({});
        let body;
        if(currentUser.tipo?.toLowerCase() === "premium"){
            body = formDataPremium;
        }else{
            body = formData;
        }
        if(validateForm(body)){
            updateUser(body);
        }
    };

    const getProfilePic = async () => {
        try {
            instanceUsers.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instanceUsers.get(`/getProfilePic/${encodeURIComponent(currentUser.profile)}`).then((response) => {
                setLoading(false);
                setPhoto(response.data);
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado al cargar la imagen de perfil",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });
            setChange(!change);

        }
    }

    const handleSubmitReset = async(e) => {
        e.preventDefault();
        setErrors({});
        if(validateFormReset()){
            updatePassword();
        }
    };

    const handleDeleteFile = (fileName) => {
        let temp = image.filter(function(f) { return f.name !== fileName });
        setImage(temp);
    };
    
    const handleChangeFile = async(e) => {
        let previusCount = parseInt(countInput.current.innerText);
        let validType = validFileType(e.target.files[0]);
        let validSize = validFileSize(e.target.files[0]);
        if(!validType){
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "El formato del archivo debe ser png, jpg o pdf."}));
        }
        else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
            }
        }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                let result = e.target.files[0];
                try{
                    setLoadingCompress(true);
                    result = await compressImageUpload(e.target.files[0]);
                }catch(error){
                    setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "Vaya, parece que algo ha salido mal..."}));
                }finally{
                    if(previusCount === parseInt(countInput.current.innerText)){
                        setLoadingCompress(false);
                    }
                }
                if(previusCount === parseInt(countInput.current.innerText)){
                    readFile(result);
                }
            }
        }
    };

    const readFile = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setImage([{
                content: reader.result.split(",")[1],
                formato: "." + file.type.split("/").pop(),
                name: file.name
            }]);
            setErrors(errors => ({...errors, errorContent: false, errorContentMsg: ""}));
        };
        reader.onerror = function (error) {
            setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "Vaya, parece que el algo ha salido mal..."}));
        };
    }

    const updatePassword = async() => {
        setOpen(false);
        try {
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.post(`/clubCaza/changePassword`, formDataReset).then(() => {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Cambio de contraseña solicitado correctamente',
                    showConfirmButton: false,
                    timer: 2500
                  })
                setChange(!change);
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado al actualizar los datos, intentalo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });
            setChange(!change);

        }
    };

    const updateUser = async(body) => {
        instanceUsers.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`};
        setIsPending(true);
        try {
            await instanceUsers.post(`/${currentUser.pk}`, body).then( async (response) => {
                setLoading(false);
                currentUser.nombre = response.data.data.nombre;
                currentUser.apellido = response.data.data.apellido;
                currentUser.pk = response.data.data.pk;
                currentUser.nTelefono = response.data.data.nTelefono;
                currentUser.provincia = response.data.data.provincia;
                currentUser.poblacion = response.data.data.poblacion;
                currentUser.codigoPostal = response.data.data.codigoPostal;
                currentUser.direccion = response.data.data.direccion;
                currentUser.iban = response.data.data.iban;

                setCurrentUser({...currentUser});
                window.localStorage.setItem("userData", JSON.stringify(currentUser));

                if(image.length !== 0){
                    let bodyImage;
                    let allImages = [];

                    image.map((i) => {
                        bodyImage = {
                            idUsuario: currentUser.pk,
                            nombre: "profile-pic",
                            formato: i.formato,
                            content: i.content
                        }
                        allImages.push(instanceUsers.post(`/uploadProfilePic/${currentUser.pk}`, bodyImage));
                    });
                    Promise.all(allImages).then(function(values) {
                        currentUser.profile = values[0].data.key;
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualización de usuario satisfactoria',
                            showConfirmButton: false,
                            timer: 2500
                          });
                        getProfilePic();
                        setIsPending(false);
                        setChange(!change);
                    }).catch(function(e) {
                        Swal.fire({
                            icon: "error",
                            title: "error",
                            text: "Se ha producido un error al actualizar la foto de perfil",
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: "Aceptar"
                        });
                        setIsPending(false);
                        setChange(!change);
                    });
                }else{
                    Swal.fire({
                        icon: 'success',
                        title: 'Actualización de usuario satisfactoria',
                        showConfirmButton: false,
                        timer: 2500
                    });
                    setIsPending(false);
                    setChange(!change);
                }         
            } );

        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado al actualizar los datos, intentalo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });
            setIsPending(false);
            setChange(!change);
        }
    }

    const validateForm = (body) =>{
        let valid = true;
        setErrors({});
            if(body.nombre === undefined || body.nombre === ''){
                document.getElementById("nombre").style.borderBottom = "2px solid #c54b1a";
                valid = false;
            }else{
                document.getElementById("nombre").style.borderBottom = "1px solid #F2F2F2";
            }
            if(body.apellido === undefined || body.apellido === ''){
                document.getElementById("apellido").style.borderBottom = "2px solid #c54b1a";
                valid = false;
            }else{
                document.getElementById("apellido").style.borderBottom = "1px solid #F2F2F2";
            }
            if(body.pk === undefined || body.pk === ''){
                document.getElementById("pk").style.borderBottom = "2px solid #c54b1a";
                valid = false;
            }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(body.pk)){
                document.getElementById("pk").style.borderBottom = "1px solid #F2F2F2";
                setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es válido."}));
                valid = false;
            }else{
                document.getElementById("pk").style.borderBottom = "1px solid #F2F2F2";
            }
            if(currentUser.tipo?.toLowerCase() === "premium"){
                if(body.nTelefono === undefined || body.nTelefono === ''){
                    document.getElementById("nTelefono").style.borderBottom = "2px solid #c54b1a";
                    valid = false;
                }else if(!/^[0-9]{9}$/i.test(body.nTelefono)){
                    document.getElementById("nTelefono").style.borderBottom = "1px solid #F2F2F2";
                    setErrors(errors => ({...errors, errorPhone: true, errorPhoneMsg: "El teléfono debe contener 9 dígitos."}));
                    valid = false;
                }else{
                    document.getElementById("nTelefono").style.borderBottom = "1px solid #F2F2F2";
                }
                if(body.provincia === undefined || body.provincia === ''){
                    document.getElementById("provincia").style.borderBottom = "2px solid #c54b1a";
                    valid = false;
                }else{
                    document.getElementById("provincia").style.borderBottom = "1px solid #F2F2F2";
                }
                if(body.poblacion === undefined || body.poblacion === ''){
                    document.getElementById("poblacion").style.borderBottom = "2px solid #c54b1a";
                    valid = false;
                }else{
                    document.getElementById("poblacion").style.borderBottom = "1px solid #F2F2F2";
                }
                if(body.direccion === undefined || body.direccion === ''){
                    document.getElementById("direccion").style.borderBottom = "2px solid #c54b1a";
                    valid = false;
                }else{
                    document.getElementById("direccion").style.borderBottom = "1px solid #F2F2F2";
                }
                if(body.codigoPostal === undefined || body.codigoPostal === ''){
                    document.getElementById("codigoPostal").style.borderBottom = "2px solid #c54b1a";
                    valid = false;
                }else if(body.codigoPostal !== null && !/^[0-9]{5}$/i.test(body.codigoPostal)){
                    document.getElementById("codigoPostal").style.borderBottom = "1px solid #F2F2F2";
                    setErrors(errors => ({...errors, errorCp: true, errorCpMsg: "El codigo postal debe tener 5 dígitos."}));
                    valid = false;
                }else{
                    document.getElementById("codigoPostal").style.borderBottom = "1px solid #F2F2F2";
                }
            }
            
            if(!valid){
                window.scrollTo({top:0,left:0, behavior: "smooth"});
            }

            return valid;
    }

    const validateFormReset = () =>{
        let valid = true;
            if(formDataReset.oldPassword === undefined || formDataReset.oldPassword === ''){
                setErrors(errors => ({...errors, errorOldPassword: true, errorOldPasswordMsg: "La contraseña no debe estar vacía"}));
                valid = false;
            }
            if(formDataReset.newPassword === undefined || formDataReset.newPassword === ''){
                setErrors(errors => ({...errors, errorNewPassword: true, errorNewPasswordMsg: "La nueva contraseña no debe estar vacía."}));
                valid = false;
            }else if(formDataReset.newPassword === formDataReset.oldPassword){
                setErrors(errors => ({...errors, errorNewPassword: true, errorNewPasswordMsg: "La nueva contraseña no puede ser la misma que la antigua."}));
                valid = false;
            }
            if(!valid){
                setOpen(true);
            }

            return valid;
    }


    const isPremiumVista = () => {
        if (currentUser.tipo?.toLowerCase() === "premium") {
            return (
                <>

                    <p className="cdc-profile-table-right">TELÉFONO:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.nTelefono}</h2>

                    <p className="cdc-profile-table-right">DIRECCIÓN:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.direccion}</h2>

                    <p className="cdc-profile-table-right">PROVINCIA:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.provincia}</h2>

                    <p className="cdc-profile-table-right">POBLACIÓN:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.poblacion}</h2>

                    <p className="cdc-profile-table-right">CÓDIGO POSTAL:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.codigoPostal}</h2>

                    <p className="cdc-profile-table-right">IBAN:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.iban}</h2>
                    
                </>
            );
        }
    }

    const isPremiumEditar = () => {
        if (currentUser.tipo?.toLowerCase() === "premium"){
            return(
            <>

                    <p className="cdc-profile-table-right">TELÉFONO:</p>
                        <input id="nTelefono" className="cdc-profile-table-left" required defaultValue={currentUser.nTelefono} onChange={(e) => {
                                    setFormDataPremium({
                                        ...formDataPremium,
                                        nTelefono: e.target.value,
                                    });
                            }}></input>
                    {errors.errorPhone && <span style={{color: "red"}}>{errors.errorPhoneMsg}</span>}

                    <p className="cdc-profile-table-right">DIRECCIÓN:</p>
                    <input id="direccion" className="cdc-profile-table-left" required defaultValue={currentUser.direccion} onChange={(e) => {
                                setFormDataPremium({
                                    ...formDataPremium,
                                    direccion: e.target.value,
                                });
                        }}></input>
                    {errors.errorDireccion && <span style={{color: "red"}}>{errors.errorDireccion}</span>}
                    <div className="public-join-form-field">
                        <div className="public-join-field">
                            <p className="cdc-profile-table-right">PROVINCIA:</p>
                            <select className="cdc-profile-table-select" name="provincia" id="provincia" value={formDataPremium.provincia ? formDataPremium.provincia : ""} onChange={(e) => {
                                            setFormDataPremium({
                                                ...formDataPremium,
                                                provincia: e.target.value,
                                            });
                                        }}>
                                    <option value="Álava/Araba">Álava/Araba</option>
                                    <option value="Albacete">Albacete</option>
                                    <option value="Alicante">Alicante</option>
                                    <option value="Almería">Almería</option>
                                    <option value="Asturias">Asturias</option>
                                    <option value="Ávila">Ávila</option>
                                    <option value="Badajoz">Badajoz</option>
                                    <option value="Baleares">Baleares</option>
                                    <option value="Barcelona">Barcelona</option>
                                    <option value="Burgos">Burgos</option>
                                    <option value="Cáceres">Cáceres</option>
                                    <option value="Cádiz">Cádiz</option>
                                    <option value="Cantabria">Cantabria</option>
                                    <option value="Castellón">Castellón</option>
                                    <option value="Ceuta">Ceuta</option>
                                    <option value="Ciudad Real">Ciudad Real</option>
                                    <option value="Córdoba">Córdoba</option>
                                    <option value="Cuenca">Cuenca</option>
                                    <option value="Gerona/Girona">Gerona/Girona</option>
                                    <option value="Granada">Granada</option>
                                    <option value="Guadalajara">Guadalajara</option>
                                    <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                                    <option value="Huelva">Huelva</option>
                                    <option value="Huesca">Huesca</option>
                                    <option value="Jaén">Jaén</option>
                                    <option value="La Coruña/A Coruña">La Coruña/A Coruña</option>
                                    <option value="La Rioja">La Rioja</option>
                                    <option value="Las Palmas">Las Palmas</option>
                                    <option value="León">León</option>
                                    <option value="Lérida/Lleida">Lérida/Lleida</option>
                                    <option value="Lugo">Lugo</option>
                                    <option value="Madrid">Madrid</option>
                                    <option value="Málaga">Málaga</option>
                                    <option value="Melilla">Melilla</option>
                                    <option value="Murcia">Murcia</option>
                                    <option value="Navarra">Navarra</option>
                                    <option value="Orense/Ourense">Orense/Ourense</option>
                                    <option value="Palencia">Palencia</option>
                                    <option value="Pontevedra">Pontevedra</option>
                                    <option value="Salamanca">Salamanca</option>
                                    <option value="Segovia">Segovia</option>
                                    <option value="Sevilla">Sevilla</option>
                                    <option value="Soria">Soria</option>
                                    <option value="Tarragona">Tarragona</option>
                                    <option value="Tenerife">Tenerife</option>
                                    <option value="Teruel">Teruel</option>
                                    <option value="Toledo">Toledo</option>
                                    <option value="Valencia">Valencia</option>
                                    <option value="Valladolid">Valladolid</option>
                                    <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                                    <option value="Zamora">Zamora</option>
                                    <option value="Zaragoza">Zaragoza</option>
                                </select>

                            {errors.errorProvincia && <span style={{color: "red"}}>{errors.errorProvinciaMsg}</span>}

                        </div>
                    </div>
                    <p className="cdc-profile-table-right">POBLACIÓN:</p>
                    <input id="poblacion" className="cdc-profile-table-left" required defaultValue={currentUser.poblacion} onChange={(e) => {
                                setFormDataPremium({
                                    ...formDataPremium,
                                    poblacion: e.target.value,
                                });
                        }}></input>
                    {errors.errorPoblacion && <span style={{color: "red"}}>{errors.errorPoblacion}</span>}

                    <p className="cdc-profile-table-right">CÓDIGO POSTAL:</p>
                        <input id="codigoPostal" className="cdc-profile-table-left" required defaultValue={currentUser.codigoPostal} onChange={(e) => {
                                    setFormDataPremium({
                                        ...formDataPremium,
                                        codigoPostal: e.target.value,
                                    });
                            }}></input>
                    {errors.errorCp && <span style={{color: "red"}}>{errors.errorCpMsg}</span>}

                    <p className="cdc-profile-table-right">IBAN:</p>
                    <input id="iban" className="cdc-profile-table-left" required defaultValue={currentUser.iban} onChange={(e) => {
                                setFormDataPremium({
                                    ...formDataPremium,
                                    iban: e.target.value,
                                });
                        }}></input>
                    {errors.iban && <span style={{color: "red"}}>{errors.iban}</span>}

                    
            </>
            );
        }
    }

    return (
        <>
        {loading ? (
            <CircularProgress />
        ) : (
        <div className="cdc-profile">
            <div className="cdc-profile-banner">
                {photo !== undefined && 
                    <img src={photo} alt="Imagen de perfil"/>
                }
                {photo === undefined && 
                    <img src={perfil} alt="Imagen de perfil"/>
                }
                <div className="cdc-profile-banner-texto">
                    <h2 className="cdc-profile-banner-texto-apellido">{currentUser.apellido}</h2>
                    <h1 className="cdc-profile-banner-texto-nombre">{currentUser.nombre}</h1>
                </div>
            </div>
            {image.length > 0 && change ?
                <div style={{marginBottom: "12px", marginTop: "10px"}}>
                    {image.map((file, index) => {
                        return (
                            <div key={"archivo-adjunto-" + index} className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)", justifyContent: "space-between", padding: "0 5px"}}>
                                <div className="flex-container align-center">
                                <FilePresentIcon style={{ fill: '#304D30', width: '30px', height: '30px' }}/>
                                    <p>{file.name}</p>
                                </div>
                                <button className="cdc-backoffice-file-delete" type="button" style={{marginLeft: "5px"}} onClick={() => handleDeleteFile(file.name)}><ClearIcon style={{ fill: '#1565c0'}}/></button>
                            </div>
                        )
                    })
                    }
                </div>
                :
                <>
                </>
            }
            {image.length === 0 && change &&
                <>
                    {loadingCompress ?
                        <Box className="loading-inline" style={{marginTop: "10px"}}>
                            <CircularProgress />
                            <p>Comprimiendo imagen...</p>
                        </Box>
                    :
                    <div>
                        <button type="button" className="cdc-backoffice-file" >
                            <input className="cdc-backoffice-file-upload" id="cdc-backoffice-file-upload" type="file" name="cdc-backoffice-file-upload" onChange={handleChangeFile} onClick={(event)=> { event.target.value = null}}/>
                            <label htmlFor="cdc-backoffice-file-upload" className="cdc-backoffice-file-label">
                                <DriveFolderUploadIcon style={{fill: "#304D30"}} alt={"Subir nuevo documento"}/>
                                <span style={{marginLeft: "5px", color: "#304D30"}}>Nueva imagen de perfil</span>
                            </label>
                        </button>
                        {errors.errorFile && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorFileMsg}</span>}
                    </div>
                    }
                </>
            }
            <div className="cdc-profile-banner-socio-premium">
                <div className="cdc-profile-banner-premium">
                    <h4 className="cdc-profile-banner-texto-premium">Número de Socio</h4>
                    <h1 className="cdc-profile-banner-texto-socio">{currentUser.nroSocio ? currentUser.nroSocio : "-"}</h1>
                </div>
                <div className="cdc-profile-banner-premium">
                    <h4 className="cdc-profile-banner-texto-premium">Puntos Acumulados</h4>
                    <h1 className="cdc-profile-banner-texto-socio-puntos" style={{fontSize: currentUser.puntos ? "24px" : "40px"}}>{currentUser.puntos ? currentUser.puntos : "0"}</h1>
                </div>
            </div>
            {change ? (
                <>
                <div component="form" className="cdc-profile-table">
                    <p className="cdc-profile-table-right">NOMBRE:</p>
                    <input id="nombre" type="text"className="cdc-profile-table-left" required defaultValue={currentUser.nombre} onChange={(e) => {
                        if(currentUser.tipo?.toLowerCase() === "premium"){
                            setFormDataPremium({
                                ...formDataPremium,
                                nombre: e.target.value,
                            });
                        }else{
                            setFormData({
                                ...formData,
                                nombre: e.target.value,
                            });
                        }
                        
                    }}></input>
                    {errors.errorNombre && <span style={{color: "red"}}>{errors.errorNombreMsg}</span>}

                    <p className="cdc-profile-table-right">APELLIDOS:</p>
                    <input id="apellido" className="cdc-profile-table-left" required defaultValue={currentUser.apellido} onChange={(e) => {
                            if(currentUser.tipo?.toLowerCase() === "premium"){
                                setFormDataPremium({
                                    ...formDataPremium,
                                    apellido: e.target.value,
                                });
                            }else{
                                setFormData({
                                    ...formData,
                                    apellido: e.target.value,
                                });
                            }
                        }}></input>
                    {errors.errorApellido && <span style={{color: "red"}}>{errors.errorApellidoMsg}</span>}

                    <p className="cdc-profile-table-right">EMAIL:</p>
                    <input id="pk" className="cdc-profile-table-left" disabled defaultValue={currentUser.pk} onChange={(e) => {
                            if(currentUser.tipo?.toLowerCase() === "premium"){
                                setFormDataPremium({
                                    ...formDataPremium,
                                    pk: e.target.value,
                                });
                            }else{
                                setFormData({
                                    ...formData,
                                    pk: e.target.value,
                                });
                            }
                        }}></input> 
                    {errors.errorEmail && <span style={{color: "red"}}>{errors.errorEmailMsg}</span>}

                    {isPremiumEditar()}                   
                </div>
                <div className="cdc-profile-links">
                    <div className="cdc-profile-editar">
                        <p className="cdc-profile-editar-txt">¿Cambiar Contraseña?</p>
                        <p className="cdc-profile-editar-clickable" onClick={handleClickOpen}>Cambiar</p>
                        <Dialog open={open} onClose={handleCloseCancel} className="passwd-reset">
                            <figure className="passwd-reset-figure">
                                <img src={candado} alt={""} />
                            </figure>
                            <DialogContent className="passwd-reset-content">
                                <DialogContentText className="passwd-reset-content-title">
                                Te enviaremos por correo un enlace para cambiar tu contraseña.
                                </DialogContentText>
                                <p className="passwd-reset-content-email">CONTRASEÑA ACTUAL:</p>
                                <input  required onChange={(e) => {
                                    setFormDataReset({
                                        ...formDataReset,
                                        oldPassword: e.target.value,
                                    });
                                }}>
                                </input>
                                {errors.errorOldPassword && <span style={{color: "red"}}>{errors.errorOldPasswordMsg}</span>}
                                <p className="passwd-reset-content-email">CONTRASEÑA NUEVA:</p>
                                <input  required onChange={(e) => {
                                    setFormDataReset({
                                        ...formDataReset,
                                        newPassword: e.target.value,
                                    });
                                }}>    
                                </input>
                                {errors.errorNewPassword && <span style={{color: "red"}}>{errors.errorNewPasswordMsg}</span>} 
                            </DialogContent>
                            <DialogActions className="passwd-reset-content-buttons">
                                <Button onClick={handleSubmitReset} className="passwd-reset-content-button" variant="contained">Cambiar Contraseña</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
                <Box className="cdc-profile-form-buttons">
                    <Button variant="contained" className="cdc-profile-form-button-volver" onClick={changeToForm}>Volver</Button>
                    { !isPending && <Button variant="contained" className="cdc-profile-form-button-guardar" onClick={handleSubmitUpdateUser} disabled={loadingCompress}>Guardar Cambios</Button>}
                    { isPending && <Button variant="contained" sx={{marginLeft:"10px"}} className="cdc-profile-form-button-guardar" disabled={true}>Enviando...</Button>}
                </Box>
                </>
            ) : ( 
                <>
                <div className="cdc-profile-table">
                    <p className="cdc-profile-table-right">NOMBRE:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.nombre}</h2>

                    <p className="cdc-profile-table-right">APELLIDOS:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.apellido}</h2>

                    <p className="cdc-profile-table-right">EMAIL:</p>
                    <h2 className="cdc-profile-table-left">{currentUser.pk}</h2>

                    {isPremiumVista()}
                </div>
                <div className="cdc-profile-links">
                    <div className="cdc-profile-editar">
                        <p className="cdc-profile-editar-txt">¿Editar perfil?</p>
                        <p className="cdc-profile-editar-clickable" onClick={changeToForm}>Editar</p>
                    </div>
                    <div className="cdc-profile-invitar">
                        <p className="cdc-profile-editar-txt">¿Invitar a un amigo?</p>
                        <p className="cdc-profile-editar-clickable" onClick={changeToInvitacion} >Invitar</p>
                    </div>
                </div>
            </>
            )}
        </div>
        )
        }
        <div className="doc-counter" style={{display: 'none'}} ref={countInput}>1</div>
    </>
    );
}