import { Container } from "@mui/material";
import { Link } from "react-router-dom";

export const Pending = () => {

    return(
        <Container className="cdc-join" style={{color:"#000"}}>
            <h1 className="cdc-join-title">Tu solicitud de socio se encuentra actualmente en revisión.</h1>
            <p className="cdc-join-text">Cuando se resuelva la solicitud podrás disfrutar de todas las ventajas que puede ofrecerte el club.</p>
            <Link to="/home">
                Volver a la página de inicio.
            </Link>
        </Container>
    );
}