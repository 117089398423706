import React, { useState } from 'react';
import {Container} from '@mui/material';
import FirstForm from '../../components/forms/formregister/EsentialInfo';
import RegisterComplete from './RegisterComplete';


export default function Register() {

    const [completed, setCompleted] = useState(false);

    //TODO añadir mas parametros de los form
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        password: '',
        nTelefono: '',
        suscripcion: false
      });


      return (
        <>
            <Container component="main" className="register-main">
                {completed ?
                    <RegisterComplete />
                :
                    <FirstForm formData={formData} setFormData={setFormData} setCompleted={setCompleted}/>
                }
            </Container>
        </>
      )
};
    

    
