export function FilteringService(json, query, resource){
    
    function filter(key, json){
        return json.filter(a => String(a[key]?.toLowerCase()).includes(query.filter[key]?.toLowerCase()));
    }

    function filterByValue(clave, json ) {
       return json.filter(o => 
            Object.keys(o).some(k => {
                if(typeof o[k] !== "string" && o[k] !== null){
                    return JSON.stringify(o[k]?.toString()).includes(query.filter[clave]?.toLowerCase());
                }else if(o[k] !== null){
                    return o[k]?.toLowerCase().includes(query.filter[clave]?.toLowerCase());
                }
            })   
        );
    }

    function useFilterFields(){
        let result;
        if(resource === "sorteos" || resource === "gestiones/allMessages" || resource === "documentos"){
            result = JSON.parse(JSON.stringify(json.Items)); 
        }else{
            result = JSON.parse(JSON.stringify(json.data));
        }
  
        Object.keys(query.filter).forEach(key => {
            if(key === 'q'){
                result = filterByValue(key,result);
            }else{
                result = filter(key, result);
            }  
        });
        return {data: result};
    }
    return useFilterFields();

}
export default FilteringService;
    