import { Box, Dialog, DialogActions, DialogContent} from "@mui/material";
import { format } from "date-fns";
import parse from "html-react-parser";


export default function Notifications({open, setOpen, notification}) {


    const handleClose = () => {
        setOpen(false);
    }

    return(
        <Dialog open={open} onClose={handleClose} className="cdc-notifications-modal-success">
            <DialogContent className="cdc-notifications-modal">
                {notification && 
                    <Box>
                        <Box className="cdc-notifications-modal-top">

                            <p className="cdc-notifications-date noMargin">{format(new Date(notification?.fechaCreacion), "dd/MM/yyyy")}</p>
                            {/*<div className="cdc-notifications-extend"></div>*/}
                            <p className="cdc-notifications-date cdc-notifications-hhmm noMargin">{format(new Date(notification?.fechaCreacion), "HH:mm")}</p>
                        </Box>
                        <Box>
                            <h2 className="cdc-notifications-modal-title">{notification?.titulo}</h2>
                            <hr className="cdc-notifications-modal-separator"></hr>
                            <p className="cdc-notifications-modal-summary">{parse(notification?.mensaje)}</p>
                        </Box>
                        
                        <DialogActions className="cdc-notifications-modal-close"> 
                            <button type="button" onClick={handleClose} variant="contained">Cerrar</button>
                        </DialogActions>
                    </Box>
                }
            </DialogContent>
        </Dialog>
    );
}