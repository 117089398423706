import { CircularProgress, Grid, Button, Box} from '@mui/material';
import { instanceAWSbo } from "../../../src/utils/axios-config";
import { useAuth } from '../../firebase/Auth';
import licencias from '../../assets/licencia2.svg';
import { Join } from '../../components/join/Join';
import Swal from 'sweetalert2';
import { useState } from 'react';

export const License = () => {

    const { currentUser } = useAuth();
    const [comunidades, setComunidades] = useState([]);
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        sendForm();
    }

    const getCheckboxes = () => {
        var x = [].map.call(document.querySelectorAll('[name="check"]:checked'), function(v) {
            return v.value
          }).join(' ,');
        setComunidades(x);
    }

    const handleSelect = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;
     
        if (isChecked) {
          setComunidades([...comunidades, value]);
        } else {
          const filteredList = comunidades.filter((item) => item !== value);
          setComunidades(filteredList);
        }
      };

    const sendForm = async () => {
        //getCheckboxes();
        let comunidadesString= "";
        let sizeComunidades = comunidades.length;
        comunidades.map((c, index)=>{
            if((comunidades.length - 1) !== index && comunidadesString !== ""){
                comunidadesString = comunidadesString + ", " + c;
            }else if((comunidades.length - 1) === index && comunidadesString !== ""){
                comunidadesString = comunidadesString + " y " + c;
            }else{
                comunidadesString = c;
            }
        });
        const body = {
            idUsuario: currentUser.pk,
            nombre: currentUser.nombre,
            apellido: currentUser.apellido,
            nTelefono: currentUser.nTelefono,
            tipoGestion: "Licencia",
            titulo: "Solicitud Licencia",
            mensaje: "Me gustaría que el club me tramitara las licencia de caza de estas comunidades: " + comunidadesString + ""
        };

        try {
            await instanceAWSbo.post(`/newGestion/`, body).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Consulta Enviada correctamente',
                    showConfirmButton: true,
                    timer: 1500
                })
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Error en la petición. vuelve a intentarlo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
              })
        }
    }

    return (
        <>
        {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Licencias"}/> :
            <Grid className='cdc-license'>
                <figure>
                    <img src={licencias} alt={"Licencias"}/>
                </figure>
                <Grid className='cdc-license-userT'>
                    <h1>Solicitud de licencia</h1>
                    
                    <p>NOMBRE</p>
                    <h2>{currentUser.nombre}</h2>

                    <p>APELLIDOS</p>
                    <h2>{currentUser.apellido}</h2>

                    <p>EMAIL</p>
                    <h2>{currentUser.pk}</h2>

                    <h3>Seleccione la opción para las que desea solicitar su licencia.</h3>
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        <Box className='cdc-license-userT-checkform'>
                            <div className='cdc-license-userT-checkform-cols'>
                                <div className='cdc-license-userT-checkform-cols-gridCol1'>
                                    <p>
                                        <input type="checkbox" id="inter" name="check" value="Interautonomica"  onChange={handleSelect}/> Interautonomica<br></br>
                                        <input type="checkbox" id="aragon" name="check" value="Aragon"  onChange={handleSelect}/> Aragón<br></br>
                                        <input type="checkbox" id="asturias" name="check" value="Asturias" onChange={handleSelect}/> Asturias<br></br>
                                        <input type="checkbox" id="canarias" name="check" value="Canarias" onChange={handleSelect}/> Canarias<br></br>
                                        <input type="checkbox" id="cMancha" name="check" value="Castilla la Mancha"  onChange={handleSelect}/> Castilla la Mancha<br></br>
                                        <input type="checkbox" id="extremadura" name="check" value="Extremadura" onChange={handleSelect}/> Extremadura<br></br>
                                        <input type="checkbox" id="rioja" name="check" value="Rioja" onChange={handleSelect}/> La Rioja<br></br> 
                                        <input type="checkbox" id="murcia" name="check" value="Murcia" onChange={handleSelect}/> Murcia<br></br>                                
                                        <input type="checkbox" id="pvasco" name="check" value="País Vasco" onChange={handleSelect}/> País Vasco<br></br>
                                    </p>
                                </div>
                                <div className='cdc-license-userT-checkform-cols-gridCol2'>
                                    <p>
                                        <input type="checkbox" id="andalucia" name="check" value="Andalucia" onChange={handleSelect}/> Andalucía<br></br>
                                        <input type="checkbox" id="baleares" name="check" value="Baleares"  onChange={handleSelect}/> Baleares<br></br>
                                        <input type="checkbox" id="contabria" name="check" value="Cantabria"  onChange={handleSelect}/> Cantabria<br></br>
                                        <input type="checkbox" id="cLeon" name="check" value="Castilla y Leon" onChange={handleSelect}/> Castilla y León<br></br>
                                        <input type="checkbox" id="catalunia" name="check" value="Cataluña"  onChange={handleSelect}/> Cataluña<br></br>
                                        <input type="checkbox" id="galicias" name="check" value="Galicia" onChange={handleSelect}/> Galicia<br></br>
                                        <input type="checkbox" id="madrid" name="check" value="Madrid" onChange={handleSelect}/> Madrid<br></br>
                                        <input type="checkbox" id="navarra" name="check" value="Navarra" onChange={handleSelect} /> Navarra<br></br>
                                        <input type="checkbox" id="valencia" name="check" value="Valencia" onChange={handleSelect}/> Valencia<br></br>
                                    </p>
                                </div>
                            </div>
                            <Button className='cdc-license-userT-checkform-btn' type='submit'>Enviar solicitud de licencia</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        }
        </>
    );
    
}