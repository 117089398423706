import { createContext, useContext, useEffect, useMemo, useState } from "react";
//import app from "./FirebaseConfig";
//import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Backdrop, CircularProgress } from "@mui/material";
import { instanceAWS } from "../utils/axios-config";
import Swal from "sweetalert2";

const AuthContext = createContext();

export default function AuthProvider({children}) {
    const [ currentUser, setCurrentUser ] = useState();
    const [ tokenMessage, setTokenMessage ] = useState();
    const [ sessionToken, setSessionToken ] = useState();
    const [ backofficeToken, setBackofficeToken ] = useState();
    const [ loading, setLoading ] = useState(true);
    //const auth = getAuth(app);

    const signIn = async (email, pass) => {
        const body = {
            email: email,
            password: pass,
            token: tokenMessage
        };
        let response = undefined;
        try {
            await instanceAWS.post(`/login`, body).then((userCredentials) => {
                setCurrentUser(userCredentials.data.user);
                setSessionToken(userCredentials.data.sessionToken);
                window.localStorage.setItem("token", userCredentials.data.sessionToken);
                window.localStorage.setItem("userData", JSON.stringify(userCredentials.data.user));
                response = userCredentials.data;
                setLoading(false);
                //return response;
            });
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });
            setLoading(false);
            response = error;
            //return response;
        } finally {
            return response;
        }
    };

    //Sesion Backoffice
    const singInBackoffice = async (user, pass) => {
        const body = {
            username: user,
            password: pass
        };

        try {
            await instanceAWS.post(`/loginBackOffice`, body).then((response) => {
                setBackofficeToken(response.data.sessionToken);
                window.localStorage.setItem("tokenBackoffice", response.data.sessionToken);
                console.log(backofficeToken);
                    if(response.data.sessionToken === undefined){
                        Swal.fire({
                            icon: "error",
                            title: "error",
                            text: "Credenciales introducidas incorrectas",
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: "Aceptar"
                        })
                    }
            })
        } catch (error) {
            console.warn(error);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        }
    };  

    //CONEXIÓN A FIREBASE
    /*const signIn = async (email, pass) => {
        await signInWithEmailAndPassword(auth, email, pass).then((userCredentials) => {
            setCurrentUser(userCredentials.user.uid);
            window.localStorage.setItem("token", userCredentials.user.uid);
        });
    };*/

    //CREACIÓN DE USUARIOS EN FIREBASE
    /*const createUser = async(email, password) => {
        await createUserWithEmailAndPassword(auth, email, password).then((userCredentials) => {
            setCurrentUser(userCredentials.user.uid);
            window.localStorage.setItem("token", userCredentials.user.uid);
        });
    };*/

    const logout = () => {
        setCurrentUser(null);
        setSessionToken(null);
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userData');
      }

      const logoutBackoffice = () => {
        setBackofficeToken(null);
        window.localStorage.removeItem('tokenBackoffice');
      }

    useEffect(() => {
        const initialState = () => {
            if(window.localStorage.getItem("userData") && window.localStorage.getItem("token")){
                return true;
            }else{
                return false;
            }
            
        }
       
        if(initialState()){
            try{
                setCurrentUser(JSON.parse(window.localStorage.getItem("userData")));
                setSessionToken(window.localStorage.getItem("token"));
            } catch(e){
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('userData');
            }
            
            setLoading(false);

        }else{
            setLoading(false);
        }

        //Hace que cuando haga f5 en el backoffice no se desloguee
        const initialStateBack = () => {
            if(window.localStorage.getItem("tokenBackoffice")){
                return true;
            }else{
                return false;
            }
        }
        if(initialStateBack()){
            try{
                setBackofficeToken(window.localStorage.getItem("tokenBackoffice"));
            } catch(e){
                window.localStorage.removeItem('tokenBackoffice');
            }
            setLoading(false);
        }else{
            setLoading(false);
        }
    }, []);

    const contextValue = useMemo(() => {
        return {
          currentUser,
          tokenMessage,
          sessionToken,
          backofficeToken,
          setSessionToken,
          setTokenMessage,
          setCurrentUser,
          setBackofficeToken,
          signIn,
          singInBackoffice,
          logout,
          logoutBackoffice,
          //createUser
        };
      }, [currentUser, tokenMessage, sessionToken, backofficeToken]);

    return (
        <AuthContext.Provider value={contextValue}>
            {loading ? (
                <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress style={{'color': 'white'}}/>
                </Backdrop>
                </>
            )
            : children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);