import { Box, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";

export default function ConfirmDialog({open, setOpen}){

    const handleClose = () => {
        setOpen({open: false, msg: ""});
    }

    return(
    <Dialog open={open.open} onClose={handleClose} className="cdc-documents-modal-success">
        <DialogContent className="cdc-documents-modal">
            <Box>
                <DialogContentText style={{marginBottom: "20px"}}>{open.msg}</DialogContentText>
                <DialogActions className="cdc-documents-modal-close"> 
                    <button type="button" onClick={handleClose} variant="contained">Cerrar</button>
                </DialogActions>
            </Box>
        </DialogContent>
    </Dialog>
    );
}