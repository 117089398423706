import {CssBaseline, Box, Typography, Container} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SegmentIcon from '@mui/icons-material/Segment';
import { useNavigate } from 'react-router-dom';

export default function Wrapper({children}) {

    const navigate = useNavigate();

    const navigateToRegister = () => {
        navigate('/registro');
    }
    
    return(
            <Container component="main" className="login-main">
                <CssBaseline />
                <Box          
                sx={{
                    background: '#FFFFFF',
                    paddingTop: 8,
                    position: "relative",
                    height: "100%"
                }}
                >
                    <div className='login-header'component="form">
                        <Box className='login-header-left'>
                            <ArrowBackIosNewIcon onClick={navigateToRegister} className='login-header-backicon'/>
                            <Typography component="h1" variant="h5" className="login-header-typography">
                                Documentos legales
                            </Typography>
                        </Box>
                        <SegmentIcon className='login-header-listicon'/>
                    </div>
                    
                    {children}
                    
                    </Box>
            </Container>
    );
}