import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../firebase/Auth";
import { instance } from "../utils/axios-config";

export const PremiumRoute = ({ children }) => {
    const [isPremium, setIsPremium] = useState(false);
    const [state, setState] = useState();
    const [loading, setLoading] = useState(true);
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        checkUser(currentUser);
    }, [currentUser]);

    const checkUser = async (user) => {
        instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        await instance.get("/users/" + user.pk).then((response) => {
            if(response.data.data.tipo.toLowerCase() === "premium"){
                setIsPremium(true);
            }
            setState(response.data.data.estado);
        }).catch(function(error) {
            console.warn(error);
            if(error.response.status === 403){
                logout();
                navigate("/login");
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return loading ? (
        <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress style={{'color': 'white'}}/>
        </Backdrop>
        </>
    ) : (isPremium ? <Navigate to="/home" /> : (state.toLowerCase() === "revision" ? <Navigate to="/hacerse-socio/pendiente" /> : children));
  };