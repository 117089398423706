export const CONFIG = {
    pages: [
        { slug: 'home', title: 'Home', breadcrumbs: [{ title: 'Inicio', to: '/home' }] },
        { slug: 'sorteos', title: 'Sorteos', breadcrumbs: [{ title: 'Sorteos', to: '/sorteos' }] },
        { slug: 'mis_sorteos', title: 'Mis sorteos', breadcrumbs: [{ title: 'Mis sorteos', to: '/mis-sorteos' }] },
        { slug: 'noticias', title: 'Noticias', breadcrumbs: [{ title: 'Noticias', to: '/blogs' }] },
        { slug: 'noticia', title: 'Noticia', breadcrumbs: [{ title: 'Noticia', to: '/blog' }] },
        { slug: 'perfil', title: 'Perfil', breadcrumbs: [{ title: 'Perfil', to: '/mi-perfil' }] },
        { slug: 'contacto', title: 'Contacto', breadcrumbs: [{ title: "Contacto", to: '/contacto' }] },
        { slug: 'hacerse_socio', title: 'Hacerme socio', breadcrumbs: [{ title: 'Hacerme socio', to: '/hacerse-socio' }] },
        { slug: 'invitar_amigo', title: 'Invitar a un amigo', breadcrumbs: [{ title: 'Invitar a un amigo', to: '/invitar-amigo' }] },
        { slug: 'notificaciones', title: 'Notificaciones', breadcrumbs: [{ title: 'Notificaciones', to: '/notificaciones' }] },
        { slug: 'documentacion', title: 'Documentación', breadcrumbs: [{ title: 'Documentación', to: '/mis-documentos' }] },
        { slug: 'servicios', title: 'Servicios', breadcrumbs: [{ title: 'Servicios', to: '/servicios' }] },
        { slug: 'licencias', title: 'Licencias', breadcrumbs: [{ title: 'Licencias', to: '/licencias' }] },
        { slug: 'seguros', title: 'Seguros', breadcrumbs: [{ title: 'Seguros', to: '/seguros' }] },
        { slug: 'mis_gestiones', title: 'Mis Gestiones', breadcrumbs: [{ title: 'Mis Gestiones', to: '/mis-gestiones' }] },
        { slug: 'detalle_gestion', title: 'Detalle de la Gestión', breadcrumbs: [{ title: 'Detalle de la Gestión', to: '/detalle-gestion' }] },
        { slug: 'documentos_legales', title: 'Documentos Legales', breadcrumbs: [{ title: 'Documentos Legales', to: '/documentos-legales' }] },
        { slug: 'ofertas', title: 'Ofertas', breadcrumbs: [{ title: 'Ofertas', to: '/ofertas' }] },
        { slug: 'oferta', title: 'Oferta', breadcrumbs: [{ title: 'Oferta', to: '/oferta' }] },
    ]
}