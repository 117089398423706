// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDwZFNFZEWCdAy0X_euAOcwZ98A48Mlftw",
  authDomain: "pwa-concept.firebaseapp.com",
  projectId: "pwa-concept",
  storageBucket: "pwa-concept.appspot.com",
  messagingSenderId: "231747805540",
  appId: "1:231747805540:web:4f3ac32d1ef716c53d5a71",
  measurementId: "G-1D0PQSB4FS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//messaging = getMessaging();

//export const messaging = getMessaging(app);

export default app;