import { createContext, useContext, useEffect, useMemo, useState } from "react";

const InstallationContext = createContext();

export default function InstallationProvider({children}) {

    const [ installed, setInstalled ] = useState(false);


    useEffect(() => {
      // For iOS
      if(window.navigator.standalone) {
        setInstalled(true);
        console.log("IOS DETECTED");
      }

      // For Androids
      if(window.matchMedia('(display-mode: standalone)').matches){
        setInstalled(true);
        console.log("Android DETECTED");
      }

      window.addEventListener('appinstalled', () => {
        console.log('Thank you for installing our app!');
        setInstalled(true);
      });
      return () => {
        window.removeEventListener('appinstalled', () => {
          console.log('Listener appinstalled removed');
        });
      }

    }, []);

    const contextValue = useMemo(() => {
      return {
        installed,
        setInstalled}
    }, [installed]);

    return (
      <InstallationContext.Provider value={contextValue}>
          {children}
      </InstallationContext.Provider>
  );
  
};

export const useInstallation = () => useContext(InstallationContext);