import { Box, CircularProgress} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Join } from "../../components/join/Join";
import SearchComponent from "../../components/search/SearchComponent";
import { format } from "date-fns";
import { useAuth } from "../../firebase/Auth";
import PPagination from "../../components/pagination/Pagination";
import { instanceWP, instance } from "../../utils/axios-config";


export const MyRaffles = () => {

    const { currentUser, sessionToken } = useAuth();
    const [ searchParams ] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [raffles, setRaffles] = useState([]);
    const [idRaffles, setIdRaffles] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(false);
    const [text, setText] = useState();

    const errorModal = () => {
        Swal.fire({
            icon: "error",
            title: "error",
            text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Aceptar"
        })
    }

    const loadPageData = useCallback (async () => {
        //let buildUrl = `/sorteo?per_page=8&page=${startPage}&acf_format=standard`;
        //buildUrl = buildUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);
        let buildUrl = `/sorteos/subscribed/${currentUser.pk}`;
        instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

        await instance.get(buildUrl).then(function(resp) {
            setError(false);
            let stringIds = resp.data.join();
            setIdRaffles(stringIds);
            return stringIds;

        }).then(async (resp) => {
            await loadMyRaffles(1, text, resp);

        }).catch(function(e) {
            setError(true);
            errorModal();
        }).finally(() => {
            setLoading(false);
            if (initialLoading) setInitialLoading(false);
        });
    }, [initialLoading]);

    const loadMyRaffles = async (startPage = 1, text, list = idRaffles) => {
        if(list !== undefined && list !== null && list !== ""){
            let buildSecondUrl = `/sorteo?per_page=8&page=${startPage}&include=${list}&acf_format=standard`;
            buildSecondUrl = buildSecondUrl + (text !== null && text !== "" && text !== undefined ? `&search=${text}`: ``);
    
            await instanceWP.get(buildSecondUrl).then((response) => {
                setRaffles(response.data);
                setTotalPages(parseInt(response.headers['x-wp-totalpages']));
            }).catch(function(e) {
                setError(true);
                if (!error) errorModal();
            }).finally(() => {
                setLoading(false);
            })
        }else{
            setLoading(false);
        }
    };

    useEffect(() => {
        if(currentUser.tipo.toLowerCase() === "premium"){
            setText(searchParams.get('text') ? searchParams.get('text') : "");
            loadPageData(1, text);
        }
    }, [searchParams]);


    return (
        <>
        {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Mis sorteos"}/> :
        (
            <div className="cdc-raffles">
                <SearchComponent text={text} setText={setText} search={loadMyRaffles} setLoading={setLoading}/>
                            
                {initialLoading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {loading ? (
                                <Box p={3} align="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    {!error ?
                                        <>
                                            {raffles.length > 0 ? (
                                                <ul className="cdc-raffles-list">
                                                    {raffles.map((newElement, index) => {
                                                        return (
                                                            <div key={"raffle-" + index}>
                                                                <Link className="cdc-raffles-list-link" to={"/sorteo/" + newElement.id}>
                                                                    <li className="cdc-raffles-container">
                                                                        {newElement.acf.image &&
                                                                        <figure>
                                                                            <img src={newElement.acf.image} alt={"Imagen " + newElement.title.rendered}/>
                                                                        </figure>
                                                                        }
                                                                        <Box className="cdc-raffles-container-right">
                                                                            <p className="cdc-raffles-date cdc-raffles-fav">{format(new Date(newElement.date), "dd/MM/yyyy")}</p>
                                                                            <h2 className="cdc-raffles-title">{newElement.title.rendered}</h2>
                                                                            <p className="cdc-raffles-summary">{newElement.acf.summary}</p>
                                                                        </Box>
                                                                    </li>
                                                                </Link>
                                                            </div>
                                                        )
                                                    })}
                                                </ul>
                                            ) : (
                                                <div className="cdc-raffles-notfound">No se han encontrado sorteos.</div>
                                            )
                                            }
                                        </>
                                        :
                                        <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                                    }
                                </>
                            )
                            }
                        </>
                    )
                }
                <Box sx={{mb: 6, mt: 4}} align="center">
                    <PPagination setLoading={setLoading} loadData={loadPageData} totalPages={totalPages} text={text} category={null}/>
                </Box>
            </div>
        )
        }
    </>
        );
}