import { Menu } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import TicketIcon from '@mui/icons-material/ConfirmationNumber';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const MyMenu = () => (
    <Menu className='back-menu'>
        <div className="cdc-backoffice-menu">
            <Menu.Item to="/backoffice-home/users" primaryText="Usuarios" leftIcon={<PersonIcon />}/>
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/users/premium" primaryText="Premium" />
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/users/basicos" primaryText="Basicos" />
        </div>
        <div className="cdc-backoffice-menu">
            <Menu.Item to="/backoffice-home/gestiones" primaryText="Tickets" leftIcon={<TicketIcon />}/>
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/gestiones/nuevas" primaryText="Nuevos" />
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/gestiones/pendientes" primaryText="En Revisión" />
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/gestiones/cerradas" primaryText="Cerrados" />
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/gestiones/allMessages" primaryText="Respuestas" />
        </div>
        <div className="cdc-backoffice-menu">
            <Menu.Item to="/backoffice-home/documentos" primaryText="Documentos" leftIcon={<InsertDriveFileIcon />}/>
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/documentos/aprobados" primaryText="Aprobados" />
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/documentos/revision" primaryText="En Revisión" />
            <Menu.Item className="cdc-backoffice-menu-sublevel" to="/backoffice-home/documentos/rechazados" primaryText="Rechazados" />
        </div>
        <div className="cdc-backoffice-menu">
            <Menu.Item to="/backoffice-home/sorteos" primaryText="Sorteos" leftIcon={<EmojiEventsIcon />}/>
        </div>
    </Menu>
);